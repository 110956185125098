import React, { useState, useEffect, useRef } from "react";
import Styles from "./index.module.css";
import Layout from "Components/Layout";
import Mainloader from "Components/loader/loader";
import { useForm, Controller } from "react-hook-form";
import ReactLoading from "react-loading";
import { Services } from "Services";
import { warningSwal, SuccessSwal } from "Util/Toast";
import { useParams, useSearchParams } from "react-router-dom";
import moment from "moment";
import { Country } from "country-state-city";
import { PhoneNumberValidate } from "Components/validators";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import TimePicker from "rc-time-picker";
import { AiOutlineClose } from "react-icons/ai";

function AddDemo() {
    const Navigate = useNavigate();
    const dropDownRef = useRef();
    const [params] = useSearchParams();
    let Countries = Country.getAllCountries();
    const [loading, setLoading] = useState(true);
    const [options, setOptions] = useState([]);
    const [searchApi, setSearchAPI] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [classStatus, setClassStatus] = useState(4);
    const [courses, setCourses] = useState([]);
    const [mainload, setMainLoad] = useState(false);
    const [details, setDetails] = useState("");
    const [parentDetails, setParentDetails] = useState({});
    const [tutors, setTutors] = useState([]);
    const [selectedTutor, setSelectedTutor] = useState(null);
    const [tutorSearchString, setTutorSearchString] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const [showDraft, setShowDraft] = useState(true);
    const [tutorSearchLoad, setTutorSearchLoad] = useState(false);

    const {
        register,
        handleSubmit,
        reset,
        watch,
        control,
        setValue,
        clearErrors,
        formState: { errors },
    } = useForm();
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const formData = watch();
    const FetchDropDowns = async () => {
        try {
            const [response1] = await Promise.all([
                Services.getCourses("GET", null, token),
            ]);
            setLoading(false);

            setCourses(response1.Courses);
        } catch (error) {
            alert("something went wrong please try again");
            console.error("Error fetching data:", error);
        }
    };
    useEffect(() => {
        const fetch = async () => {
            if (params.get("id")) {
                setMainLoad(true);
                let res = await Services.getDemoDetails(
                    "GET",
                    null,
                    token,
                    params.get("id")
                );

                setCourses([
                    {
                        CourseID: res?.Batches?.CourseID,
                        CourseName: res?.Batches?.CourseName,
                    },
                ]);
                setShowDraft(res?.Batches?.DemoClassStatus == 1 ? false : true);
                setDetails(res?.Batches);

                setTimeout(() => {
                    setSelectedTutor({
                        value: res?.Batches?.TutorID,
                        label: res?.Batches?.TutorName,
                    });
                    reset({
                        CourseID: res?.Batches?.CourseID,
                        BatchSize: res?.Batches?.BatchSize,
                        DemoStartDate: moment(
                            res?.Batches?.DemoStartDate
                        ).format("YYYY-MM-DD"),
                        DemoEndDate: moment(res?.Batches?.DemoEndDate).format(
                            "YYYY-MM-DD"
                        ),

                        DemoStartTime: moment().set({
                            hours: String(res?.Batches?.DemoStartTime).split(
                                ":"
                            )[0],
                            minutes: String(res?.Batches?.DemoStartTime).split(
                                ":"
                            )[1],
                        }),
                        DemoEndTime: moment().set({
                            hours: String(res?.Batches?.DemoEndTime).split(
                                ":"
                            )[0],
                            minutes: String(res?.Batches?.DemoEndTime).split(
                                ":"
                            )[1],
                        }),
                    });
                    setLoading(false);
                    setMainLoad(false);
                }, 200);
            } else {
                FetchDropDowns();
            }
        };
        fetch();
    }, []);
    const handleTutorChange = (searchString) => {
        setTutorSearchString(searchString);
    };
    const onTutorSelect = (searchString) => {
        setSelectedTutor(searchString);
    };
    const handleOutsideClick = (event) => {
        if (
            dropDownRef.current &&
            !dropDownRef.current.contains(event.target)
        ) {
            // Clicked outside the dropdown, so close it
            setOptions([]); // Clear the options to close the dropdown
        }
    };
    const handleInputChange = (event) => {
        if (!formData.CourseID) {
            alert("please Select Course");
            return;
        }

        setSearchTerm(event.target.value);
    };
    const handleSearch = async () => {
        if (!formData.CourseID) {
            alert("please Select Course");
            return;
        }

        if (searchTerm?.length === 0) {
            alert("Enter valid Mobile number");
            return;
        }

        Services.getDemoRequetsSearch(
            "GET",
            null,
            token,
            formData.CourseID,
            searchTerm
        )
            .then((response) => {
                if (response?.DemoRequests?.length === 0) {
                    alert("NO Student Found");
                    return;
                }

                const data = response?.DemoRequests?.map((item) => {
                    return { id: item.DemoRequestID, label: item.StudentName };
                });
                setSearchAPI(response?.DemoRequests);
                setOptions(data);
            })
            .catch((error) => {
                console.error("Error fetching options:", error);
            });
    };
    useEffect(() => {
        const debouncedSearch = setTimeout(async () => {
            if (
                typeof tutorSearchString == "string" &&
                tutorSearchString?.length > 0
            ) {
                setTutorSearchLoad(true);

                try {
                    const data = await Services.searchActiveTutor(
                        "POST",
                        JSON.stringify({ SearchString: tutorSearchString }),
                        token,
                        selectedTutor
                    );
                    const formattedOptions = data.Tutors?.filter(
                        (item) => item.TutorStatus == 1
                    )?.map((tutor) => ({
                        label: `${tutor.FirstName} ${tutor.LastName}`,
                        value: tutor.TutorID,
                        Email: tutor.EmailID,
                    }));
                    setTutors(formattedOptions);
                } catch (error) {
                    console.error("Error fetching options:", error);
                } finally {
                    setTutorSearchLoad(false);
                }
            }
        }, 300);

        return () => clearTimeout(debouncedSearch);
    }, [tutorSearchString]);
    const onSubmit = async (data, status) => {
        let selectedCourse = courses.find(
            (item) => item.CourseID == data.CourseID
        );
        console.log(selectedTutor);
        if (!selectedTutor?.label && status != 4) {
            warningSwal("Warning", "please Select Tutor");
            return;
        }
        const formattedTime = data.DemoStartTime
            ? moment(data.DemoStartTime).format("HH:mm")
            : "";
        const StartDate = moment(data.DemoStartDate).set({
            hour: moment(data?.DemoStartTime).hour(),
            minute: moment(data?.DemoStartTime).minute(),
        });
        const EndDate = moment(data.DemoEndDate).set({
            hour: moment(data?.DemoEndTime).hour(),
            minute: moment(data?.DemoEndTime).minute(),
        });
        // const diffInMinutes = EndDate.diff(StartDate, "minutes");
        /* if (diffInMinutes <= 0) {
            warningSwal("Warning", "Invalid Date time range");
            return;
        } */

        if (params.get("id")) {
            let body = {
                ...data,

                DemoCounter: selectedCourse?.DemoCounter,
                CourseName: selectedCourse?.CourseName,
                // Duration: diffInMinutes,
                DemoClassStatus: status,
                DemoStartTime: data.DemoStartTime
                    ? moment(data.DemoStartTime).format("HH:mm")
                    : "",
                /*     DemoEndTime: data.DemoEndTime
                    ? moment(data.DemoEndTime).format("HH:mm")
                    : "", */
            };

            if (details?.TutorID != selectedTutor?.value) {
                body = { ...body, TutorID: selectedTutor?.value };
            }
            setLoading(true);
            try {
                const response = await Services.UPdateDemoClass(
                    "PUT",
                    JSON.stringify(body),
                    token,
                    params.get("id")
                );
                if (response?.Status == 1) {
                    SuccessSwal("Success", response.Message);

                    Navigate(-1);
                } else {
                    warningSwal("Warning", response.Message);
                }
            } catch (error) {
                console.error("Error fetching options:", error);
            } finally {
                setLoading(false);
                setTutorSearchLoad(false);
            }
        } else {
            if (selectedOptions.length == 0 && status == 1) {
                alert("Please select a student");
                return;
            }
            if (selectedOptions.length > 4) {
                alert("Cannot select more than 4 students for a demo class");
                return;
            }
            setLoading(true);
            try {
                const response = await Services.CreateDemoClasses(
                    "POST",
                    JSON.stringify({
                        ...data,
                        TutorID: selectedTutor?.value,
                        DemoCounter: selectedCourse?.DemoCounter,
                        CourseName: selectedCourse?.CourseName,
                        Students: selectedOptions,
                        // Duration: diffInMinutes,
                        DemoClassStatus: status,
                        /*  DemoEndTime: data.DemoEndTime
                            ? moment(data.DemoEndTime).format("HH:mm")
                            : "", */
                        DemoStartTime: data.DemoStartTime
                            ? moment(data.DemoStartTime).format("HH:mm")
                            : "",
                    }),
                    token
                );
                if (response?.Status == 1) {
                    SuccessSwal("Success", response.Message);

                    Navigate(-1);
                } else {
                    warningSwal("Warning", response.Message);
                }
            } catch (error) {
                console.error("Error fetching options:", error);
            } finally {
                setLoading(false);
                setTutorSearchLoad(false);
            }
        }
    };
    const handleTimeChange = (type, event) => {
        if (type == "time1") {
            setValue("Time1", moment(event).format("HH:mm"));
            clearErrors("Time1");
        }
    };
    const buttonHandler = (e) => {
        let id = e.currentTarget.getAttribute("id");
        // eslint-disable-next-line
        if (
            selectedOptions.filter((item) => item.DemoRequestID == id)?.length >
            0
        ) {
            alert("student Already Added ");
            return;
        }
        setOptions((prev) => {
            // eslint-disable-next-line
            return prev.filter((item) => item.id != id);
        });
        setSearchTerm("");
        // eslint-disable-next-line
        let selectedData = searchApi?.filter(
            (item) => item.DemoRequestID == id
        );

        if (formData.BatchSize === "One-to-One") {
            setSelectedOptions(selectedData);
        } else {
            setSelectedOptions((prev) => {
                return [...prev, ...selectedData];
            });
        }
    };
    const deleteHandler = (e) => {
        let studentid = e.currentTarget.getAttribute("studentid");
        // eslint-disable-next-line
        let newData = selectedOptions.filter(
            // eslint-disable-next-line
            (item) => item.StudentID != studentid
        );
        setSelectedOptions(newData);
    };
    const backButton = () => {
        Navigate(-1);
    };
    return (
        <Layout Active={"Demo Classes"}>
            <div className={Styles["Container"]}>
                <button onClick={backButton} className={Styles["back"]}>
                    Back
                </button>
                <div className={Styles["wrapper"]}>
                    <h3>{params.get("id") ? "Edit" : "Add"} Class</h3>
                    {mainload ? (
                        <Mainloader />
                    ) : (
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className={Styles["form-control"]}>
                                <label htmlFor="Last Name">
                                    Course <span>*</span>
                                </label>
                                <select
                                    disabled={params.get("id")}
                                    defaultValue={""}
                                    {...register("CourseID", {
                                        required: true,
                                    })}>
                                    <option value="" disabled>
                                        Select Course
                                    </option>
                                    {courses?.map((item) => (
                                        <option value={item.CourseID}>
                                            {item.CourseName}
                                        </option>
                                    ))}
                                </select>
                                {errors.CourseID && (
                                    <span>This field is required</span>
                                )}
                            </div>
                            <div className={Styles["form-control"]}>
                                <label htmlFor="ContactNumber">
                                    Batch Size <span>*</span>
                                </label>

                                <select
                                    disabled={params.get("id")}
                                    defaultValue={""}
                                    {...register("BatchSize", {
                                        required: true,
                                    })}>
                                    <option value="" disabled>
                                        Select Batch Size
                                    </option>

                                    <option value="One-to-One">
                                        One-to-One
                                    </option>
                                    <option value={"Group"}>Group</option>
                                </select>
                                {errors.BatchSize && (
                                    <span>This field is required</span>
                                )}
                            </div>
                            <div className={Styles["form-control"]}>
                                <label htmlFor="">
                                    Demo Start Date<span>*</span>
                                </label>
                                <input
                                    className={
                                        errors?.DemoStartDate
                                            ? Styles["error"]
                                            : ""
                                    }
                                    type={"date"}
                                    {...register("DemoStartDate", {
                                        required: true,
                                    })}
                                />
                                {errors.DemoStartDate && (
                                    <span className={Styles["error-msg"]}>
                                        This field is required
                                    </span>
                                )}
                            </div>
                            <div className={Styles["form-control"]}>
                                <label htmlFor="">
                                    Demo Start Time<span>*</span>
                                </label>
                                <Controller
                                    name="DemoStartTime"
                                    control={control}
                                    defaultValue={""}
                                    rules={{
                                        required: "This field is required",
                                    }}
                                    render={({ field }) => (
                                        <>
                                            <TimePicker
                                                className={
                                                    errors.DemoStartTime
                                                        ? Styles.error
                                                        : ""
                                                }
                                                defaultOpenValue={moment()}
                                                defaultValue={
                                                    field.value || null
                                                } // Prefill with the current value
                                                minuteStep={5}
                                                allowEmpty={false}
                                                showSecond={false}
                                                placeholder="Preferred Time"
                                                onChange={(date) => {
                                                    field.onChange(date);
                                                    handleTimeChange(
                                                        "DemoStartTime",
                                                        date
                                                    );
                                                }}
                                                format="h:mm a"
                                                use12Hours
                                            />
                                            {errors.DemoStartTime && (
                                                <p
                                                    className={
                                                        Styles["error-msg"]
                                                    }>
                                                    this field s required
                                                </p>
                                            )}
                                        </>
                                    )}
                                />
                            </div>
                            {/*  <div className={Styles["form-control"]}>
                                <label htmlFor="">
                                    Demo End Date<span>*</span>
                                </label>
                                <input
                                    className={
                                        errors?.DemoEndDate
                                            ? Styles["error"]
                                            : ""
                                    }
                                    type={"date"}
                                    {...register("DemoEndDate", {
                                        required: true,
                                    })}
                                />
                                {errors.DemoEndDate && (
                                    <span className={Styles["error-msg"]}>
                                        This field is required
                                    </span>
                                )}
                            </div>
                            <div className={Styles["form-control"]}>
                                <label htmlFor="">
                                    Demo End Time<span>*</span>
                                </label>
                                <Controller
                                    name="DemoEndTime"
                                    control={control}
                                    defaultValue={""}
                                    rules={{
                                        required: "This field is required",
                                    }}
                                    render={({ field }) => (
                                        <>
                                            <TimePicker
                                                className={
                                                    errors.DemoEndTime
                                                        ? Styles.error
                                                        : ""
                                                }
                                                defaultOpenValue={moment()}
                                                defaultValue={
                                                    field.value || null
                                                } // Prefill with the current value
                                                minuteStep={5}
                                                allowEmpty={false}
                                                showSecond={false}
                                                placeholder="End Time"
                                                onChange={(date) => {
                                                    field.onChange(date);
                                                    handleTimeChange(
                                                        "DemoEndTime",
                                                        date
                                                    );
                                                }}
                                                format="h:mm a"
                                                use12Hours
                                            />
                                            {errors.DemoEndTime && (
                                                <p
                                                    className={
                                                        Styles["error-msg"]
                                                    }>
                                                    this field s required
                                                </p>
                                            )}
                                        </>
                                    )}
                                />
                            </div> */}
                            <div
                                className={Styles["form-control"]}
                                style={{ gridColumn: "1/-1" }}>
                                <label htmlFor="">Tutor</label>
                                <Select
                                    options={tutors}
                                    maxMenuHeight={90}
                                    menuPosition="absolute"
                                    menuShouldBlockScroll
                                    onInputChange={handleTutorChange}
                                    value={selectedTutor}
                                    onChange={onTutorSelect}
                                    isClearable
                                    placeholder="Search Tutor"
                                    classNamePrefix="select_demorequest"
                                    isLoading={tutorSearchLoad}
                                    styles={{
                                        // Set the width to 100%
                                        control: (provided) => ({
                                            ...provided,
                                            width: "100%",
                                        }),
                                    }}
                                />
                            </div>
                            {!params.get("id") && (
                                <div
                                    className={`${Styles["form-control"]}`}
                                    ref={dropDownRef}
                                    style={{
                                        // gridRow: "3/-1",
                                        gridColumn: "1 / -1",
                                    }}>
                                    <label htmlFor="Student">
                                        {" "}
                                        Student{" "}
                                        <span className="important">*</span>
                                    </label>
                                    <div>
                                        <div
                                            className={
                                                Styles["students-select"]
                                            }>
                                            <input
                                                type="text"
                                                value={searchTerm}
                                                placeholder="Enter phone number (without country code)"
                                                onChange={handleInputChange}
                                            />
                                            <button
                                                className={
                                                    Styles["numbersearch"]
                                                }
                                                onClick={handleSearch}
                                                type="button">
                                                Search
                                            </button>
                                            {options.length > 0 && (
                                                <div
                                                    className={
                                                        Styles[
                                                            "students-options"
                                                        ]
                                                    }>
                                                    {options.length > 0 &&
                                                        options.map(
                                                            (option) => (
                                                                <div
                                                                    key={
                                                                        option.id
                                                                    }>
                                                                    <button
                                                                        id={
                                                                            option.id
                                                                        }
                                                                        type="button"
                                                                        onClick={
                                                                            buttonHandler
                                                                        }>
                                                                        {" "}
                                                                        {
                                                                            option.label
                                                                        }
                                                                    </button>
                                                                </div>
                                                            )
                                                        )}
                                                </div>
                                            )}
                                        </div>
                                        {selectedOptions.length > 0 && (
                                            <div
                                                className={
                                                    Styles[
                                                        "selected-studentdata"
                                                    ]
                                                }>
                                                {selectedOptions.map((item) => {
                                                    return (
                                                        <div
                                                            key={`${item.StudentID}21`}>
                                                            <button
                                                                onClick={
                                                                    deleteHandler
                                                                }
                                                                studentid={
                                                                    item.StudentID
                                                                }
                                                                type="button">
                                                                <AiOutlineClose />
                                                            </button>
                                                            <p>
                                                                {
                                                                    item.StudentName
                                                                }
                                                            </p>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                            {loading ? (
                                <div className={Styles["submit_wrap"]}>
                                    <ReactLoading
                                        color="green"
                                        type="spokes"
                                        height={30}
                                        width={30}
                                    />
                                </div>
                            ) : (
                                <div className={Styles["submit_wrap"]}>
                                    <button
                                        disabled={loading}
                                        type="button"
                                        onClick={handleSubmit((data) =>
                                            onSubmit(data, 1)
                                        )}>
                                        Submit
                                    </button>
                                    {showDraft && (
                                        <button
                                            disabled={loading}
                                            type="button"
                                            onClick={handleSubmit((data) =>
                                                onSubmit(data, 4)
                                            )}>
                                            Save as Draft
                                        </button>
                                    )}
                                </div>
                            )}
                        </form>
                    )}
                </div>
            </div>
        </Layout>
    );
}

export default AddDemo;
