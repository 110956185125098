import React, { useEffect, useState } from "react";
import Layout from "Components/Layout";
import { Helmet } from "react-helmet";
import Styles from "./Payouts.module.css";
import { useForm } from "react-hook-form";
import ReactLoading from "react-loading";
import moment from "moment";
import { SuccessSwal, warningSwal } from "Util/Toast";
import { Services } from "../../Services";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { BiPlus } from "react-icons/bi";
import Loading from "../../Components/loader/loader";
function Payouts() {
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();
    const [loading, setLoading] = useState(true);
    const [load, setLoad] = useState(false);
    const [Payouts, setPayouts] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const navigate = useNavigate();
    const token = {
        Authorization: `token ${localStorage.getItem("token")}`,
    };
    const getpayouts = () => {
        Services.getbulkpayouts("GET", null, token)
            .then((response) => {
                setLoading(false);
                if (response.Status === 1) {
                    setPayouts(response?.Payouts);
                } else if (response.Status === 0) {
                    setLoading(false);
                    warningSwal("Warning", response.Message);
                }
            })
            .catch((err) => {
                // alert(err);
                setLoading(false);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    useEffect(() => {
        getpayouts();
    }, []);
    const handleSelectRow = (id) => {
        setSelectedRows((prevSelected) => {
            if (prevSelected.includes(id)) {
                return prevSelected.filter((rowId) => rowId !== id);
            } else {
                return [...prevSelected, id];
            }
        });
    };
    const handleSelectAll = () => {
        if (selectedRows.length === Payouts.length) {
            setSelectedRows([]);
        } else {
            setSelectedRows(Payouts.map((item) => item.PayoutID));
        }
    };
    const payouthandler = () => {
        setLoad(true);
        Services.updatePayoutIDs(
            "POST",
            JSON.stringify({ PayoutIDs: selectedRows, PayoutStatus: 1 }),
            token
        )
            .then((response) => {
                setLoading(false);
                setLoad(false);
                if (response.Status === 1) {
                    getpayouts();
                    setSelectedRows([]);
                    SuccessSwal("Success", response.Message);
                } else if (response.Status === 0) {
                    setLoading(false);
                    warningSwal("Warning", response.Message);
                }
            })
            .catch((err) => {
                // alert(err);
                setLoad(false);
                setLoading(false);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    return (
        <Layout Active={"bulk-payouts"}>
            {load && <Loading />}{" "}
            <Helmet>
                <meta charSet="utf-8" />
                <title>Bulk Payouts</title>
            </Helmet>
            <div className={Styles["Container"]}>
                <div className={Styles["wrapper"]}>
                    <h3>Bulk Payouts</h3>
                    <div className={Styles["control-btns"]}>
                        <button
                            onClick={() => navigate("/CreateBulk")}
                            className={Styles["button"]}>
                            <BiPlus size={20} /> Create payouts
                        </button>
                        {selectedRows.length > 0 && (
                            <button
                                onClick={payouthandler}
                                className={Styles["button"]}
                                style={{
                                    background: "#0077b6",
                                    color: "white",
                                }}>
                                Mark as paid
                            </button>
                        )}
                    </div>
                    <div className={Styles["Table"]}>
                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        {" "}
                                        <input
                                            type="checkbox"
                                            checked={
                                                selectedRows.length ===
                                                Payouts.length
                                            }
                                            onChange={handleSelectAll}
                                        />
                                    </th>
                                    <th>Payout ID</th>
                                    <th>Name</th>
                                    <th>Amount to Pay</th>
                                    <th>Total Classes</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading ? (
                                    [1, 1].map((item) => (
                                        <tr className={Styles["loading"]}>
                                            <td>
                                                <Skeleton />
                                            </td>
                                            <td>
                                                <Skeleton />
                                            </td>

                                            <td>
                                                <Skeleton />
                                            </td>
                                            <td>
                                                <Skeleton />
                                            </td>
                                            <td>
                                                <Skeleton />
                                            </td>
                                        </tr>
                                    ))
                                ) : Payouts.length > 0 ? (
                                    Payouts.map((item) => (
                                        <tr>
                                            <td>
                                                {" "}
                                                <input
                                                    type="checkbox"
                                                    checked={selectedRows.includes(
                                                        item.PayoutID
                                                    )}
                                                    onChange={() =>
                                                        handleSelectRow(
                                                            item.PayoutID
                                                        )
                                                    }
                                                />
                                            </td>
                                            <td>{item?.PayoutID}</td>
                                            <td>
                                                {item?.FirstName}{" "}
                                                {item?.LastName}
                                            </td>
                                            <td>
                                                {parseFloat(
                                                    item?.AmountToPay.toFixed(2)
                                                )}
                                            </td>
                                            <td
                                                onClick={() => {
                                                    navigate(
                                                        `/payout-details?id=${item?.PayoutID}`
                                                    );
                                                }}
                                                style={{
                                                    color: "blue",
                                                }}>
                                                {item?.EarningsIds?.length}
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={5}>No Record Found</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default Payouts;
