import Modal from "react-modal";
import React, { useEffect, useState } from "react";
import Styles from "../../../../pages/scheduledClasses/Report/report.module.css";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import ReactLoading from "react-loading";
import { Services } from "Services";
import { warningSwal } from "Util/Toast";
import Select from "react-select";
import { AiOutlineClose } from "react-icons/ai";
import swal from "sweetalert";
Modal.setAppElement("#root");

const DemoForm = ({
    modal,
    setModal,
    getDetails,
    id,
    completeData,
    setCompleteData,
}) => {
    const {
        handleSubmit,
        reset,
        register,
        setValue,
        control,
        watch,
        formState: { errors },
    } = useForm({ defaultValues: { is_partial_completed: false } });
    const token = {
        Authorization: `token ${localStorage.getItem("token")}`,
    };

    const [load, setLoad] = useState(false);

    const watchData = watch();
    useEffect(() => {
        setValue("CompletedComments", completeData);
    }, []);
    // CLOSE FORM
    const closeModal = () => {
        setModal(false);
        reset();
    };

    // FORM SUBMIT
    const onSubmit = async (formdata) => {
        setLoad(true);
        let Body = {
            CourseID: getDetails?.data?.CourseID,
            DemoClassID: id,
            DemoClassPrice: getDetails?.data?.DemoClassPrice,
            Students: getDetails.data?.Students,
            Students: getDetails?.data?.Students,
            FeePerHour: getDetails?.data?.FeePerHour,
            DemoStartTime: getDetails?.data?.DemoStartTime,
            TutorID: getDetails?.data?.TutorID,
            ActionComments: formdata.CompletedComments,
            BatchSize: getDetails?.data?.BatchSize,
            TutorEmailID: getDetails?.data?.TutorEmailID,
            DemoStartDate:
                getDetails?.data?.DemoStartDate &&
                getDetails?.data?.DemoStartDate != "NA" &&
                String(getDetails?.data?.DemoStartDate).split("T")[0],
        };

        if (completeData) {
            try {
                const CompleteClass = await Services.UPdateDemoClass(
                    "PUT",
                    JSON.stringify({
                        ActionComments: formdata.CompletedComments,
                    }),
                    token,
                    id
                );
                setLoad(false);
                if (CompleteClass.Status === 1) {
                    swal(
                        "Completed",
                        "Comment Updated Successfully",
                        "success"
                    );
                    setModal(false);
                    setCompleteData(null);
                    getDetails?.details(id);
                } else {
                    warningSwal("Warning", CompleteClass?.Message);
                }
            } catch (err) {
                setLoad(false);
                console.log(err);
                warningSwal("Error", "Something went wrong please try again");
            }
        } else {
            try {
                const CompleteClass = await Services.CompleteClasses_demo(
                    "POST",
                    JSON.stringify(Body),
                    token
                );
                setLoad(false);
                if (CompleteClass.Status === 1) {
                    swal("Completed", CompleteClass.Message, "success");
                    setModal(false);
                    setCompleteData(null);
                    getDetails?.details(id);
                } else {
                    warningSwal("Warning", CompleteClass?.Message);
                }
            } catch (err) {
                setLoad(false);
                console.log(err);
                warningSwal("Error", "Something went wrong please try again");
            }
        }
    };

    return (
        <Modal
            isOpen={modal}
            contentLabel="Modal"
            className={Styles["modal"]}
            onRequestClose={closeModal}
            overlayClassName={Styles["overlay"]}>
            <div className={Styles["form-wrapper"]}>
                <button onClick={closeModal}>
                    <AiOutlineClose />
                </button>
                <h2>Complete Class</h2>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={Styles["form-control"]}>
                        <label htmlFor="Comments">
                            Comments <span>:</span>
                        </label>

                        <textarea
                            style={{ width: "100%" }}
                            {...register("CompletedComments", {
                                required: true,
                            })}></textarea>
                    </div>

                    {/* eslint-disable-next-line */}
                    {load ? (
                        <div style={{ justifySelf: "center" }}>
                            <ReactLoading
                                color="green"
                                type="spokes"
                                height={30}
                                width={30}
                            />
                        </div>
                    ) : (
                        <div className={Styles["buttons"]}>
                            <button type="submit" className={Styles["submit"]}>
                                Complete
                            </button>
                            <button
                                type="button"
                                onClick={closeModal}
                                className={Styles["close"]}>
                                Cancel
                            </button>
                        </div>
                    )}
                </form>
            </div>
        </Modal>
    );
};
export default DemoForm;
