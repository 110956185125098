import React from "react";
import eventClasses from "./events.module.css";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { Services } from "Services";
import Loader from "Components/loader/loader";
function Events({ event, closeTooltip }) {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});
    const navHandler = (e) => {
        let id = e.currentTarget.getAttribute("id");
        window.open(`/scheduledClasses/Details?scheduleID=${id}`, "_blank");
    };
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    function getscheduledClasses() {
        Services.getScheduledClassesDetails(
            "GET",
            null,
            token,
            event?.ScheduleClassID
        )
            .then((response) => {
                if (response.Status === 1) {
                    setData(response?.ScheduleClasess);

                    setTimeout(() => {
                        setLoading(false);
                    }, 200);
                }
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
                alert("something went wrong try again");
            });
    }
    useEffect(() => {
        getscheduledClasses();
    }, []);
    return (
        <div className={eventClasses["event-container"]} id="event-details">
            <h3>{event.BatchName}</h3>
            <div className={eventClasses["content"]}>
                <p>
                    Tutor : <span>{data?.TutorName}</span>
                </p>
                <p>
                    BatchSize : <span>{data?.BatchSize}</span>
                </p>
                <p>
                    CourseName : <span>{data?.CourseName}</span>
                </p>
            </div>
            <div className={eventClasses["actions"]}>
                <button id={event.ScheduleClassID} onClick={navHandler}>
                    More Details
                </button>
                <button onClick={closeTooltip}>close</button>
            </div>
        </div>
    );
}

export default Events;
