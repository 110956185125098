import { useState, useEffect } from "react";
import React from "react";
import Layout from "../../Components/Layout";
import AvailabilityClasses from "./cancel.module.css";
import Select from "react-select";
import { Services } from "../../Services";
import { useForm } from "react-hook-form";
import Loader from "../../Components/loader/loader";
import swal from "sweetalert";
import { Error_Dark, SuccessSwal, warningSwal } from "../../Util/Toast";
function Availability() {
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const [tutors, setTutors] = useState([]);
    const [tutorSearchString, setTutorSearchString] = useState(null);
    const [selectedTutor, setSelectedTutor] = useState(null);
    const [tutorSearchLoad, setTutorSearchLoad] = useState(false);
    const [loading, setLoading] = useState(false);
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        const debouncedSearch = setTimeout(async () => {
            if (
                typeof tutorSearchString == "string" &&
                tutorSearchString.length > 0
            ) {
                setTutorSearchLoad(true);

                try {
                    const data = await Services.searchActiveTutor(
                        "POST",
                        JSON.stringify({ SearchString: tutorSearchString }),
                        token,
                        selectedTutor
                    );
                    const formattedOptions = data.Tutors.map((tutor) => ({
                        label: `${tutor.FirstName} ${tutor.LastName}`,
                        value: tutor.TutorID,
                    }));
                    setTutors([
                        ...formattedOptions,
                        { label: "ALL", value: "ALL" },
                    ]);
                } catch (error) {
                    console.error("Error fetching options:", error);
                } finally {
                    setTutorSearchLoad(false);
                }
            }
        }, 300);

        return () => clearTimeout(debouncedSearch);
    }, [tutorSearchString]);

    const handleChange = (selectedOption) => {
        setTutorSearchString(selectedOption);
    };
    const onTutorSelect = (option) => {
        if (option) {
            setSelectedTutor(option);
        }
    };
    const onSubmit = async (data) => {
        if (data.FromDate > data.ToDate) {
            setError("ToDate", { message: "Invalid Date Range" });
        }
        if (!selectedTutor) {
            alert("please select Tutor");
            return;
        }
        setLoading(true);
        let body = {
            TutorID: selectedTutor.value,
            ...data,
        };
        try {
            const data = await Services.bulk_cancel(
                "POST",
                JSON.stringify(body),
                token
            );
            if (data.Status === 1) {
                swal("Success", data.Message, "success");
            } else {
                warningSwal("Warning", data.Message);
            }
        } catch (error) {
            Error_Dark("something went wrong try again");
            console.error("Error fetching options:", error);
        } finally {
            setLoading(false);
        }
    };
    return (
        <Layout Active={"Bulkcancel"}>
            {loading && <Loader />}
            <div className={AvailabilityClasses["Container"]}>
                <div className={AvailabilityClasses["tutor"]}>
                    <h3>Bulk Cancel</h3>
                    <div className={AvailabilityClasses["filters"]}>
                        <div className={AvailabilityClasses["form-control"]}>
                            <label htmlFor="">Tutor</label>
                            <Select
                                options={tutors}
                                onInputChange={handleChange}
                                onChange={onTutorSelect}
                                value={selectedTutor}
                                isLoading={tutorSearchLoad}
                                placeholder="Search Tutor"
                                noOptionsMessage={() => "No Tutor Found"}
                                classNames={AvailabilityClasses["select"]}
                                styles={{
                                    // Set the width to 100%
                                    control: (provided) => ({
                                        ...provided,
                                        width: "100%",
                                    }),
                                }}
                            />
                        </div>
                        <form
                            onSubmit={handleSubmit(onSubmit)}
                            className={AvailabilityClasses["date-filter"]}>
                            <div
                                className={AvailabilityClasses["form-control"]}>
                                <label htmlFor="">From Date</label>
                                <input
                                    type="date"
                                    // min={new Date().toISOString().split("T")[0]}
                                    {...register("FromDate", {
                                        required: "this field is required",
                                    })}
                                />
                                {/* {errors.FromDate && (
                                    <span>{errors.FromDate.message}</span>
                                )} */}
                            </div>
                            <div
                                className={AvailabilityClasses["form-control"]}>
                                <label htmlFor="">To Date</label>
                                <input
                                    type="date"
                                    // min={new Date().toISOString().split("T")[0]}
                                    {...register("ToDate", {
                                        required: "this field is required",
                                    })}
                                />
                                {/* {errors.FromDate && (
                                    <span>{errors.FromDate.message}</span>
                                )} */}
                            </div>
                            <button>Cancel</button>
                        </form>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default Availability;
