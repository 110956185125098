import React, { useRef, useState, useEffect } from "react";
import Modal from "react-modal";
import Styles from "./add.module.css";
import { Services } from "Services";
// import { useRef } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { SuccessSwal, warningSwal } from "Util/Toast";
function Add({
    addModal,
    CourseID,
    BatchSize,
    setAddModal,
    data,
    getDetails,
    getscheduledClasses,
}) {
    const [searchTerm, setSearchTerm] = useState("");
    const [options, setOptions] = useState([]);
    // const [alloptions,setAll]
    const [selectedOptions, setSelectedOptions] = useState([]);
    const dropDownRef = useRef();
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    useEffect(() => {
        document.addEventListener("mousedown", handleOutsideClick);
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, []);
    const handleOutsideClick = (event) => {
        if (
            dropDownRef.current &&
            !dropDownRef.current.contains(event.target)
        ) {
            // Clicked outside the dropdown, so close it
            setOptions([]); // Clear the options to close the dropdown
        }
    };

    const handleInputChange = (e) => {
        setSearchTerm(e.target.value);
    };
    const handleSearch = () => {
        Services.getDemoRequetsSearch("GET", null, token, CourseID, searchTerm)
            .then((response) => {
                if (response?.DemoRequests.length === 0) {
                    alert("NO Student Found");
                    return;
                }
                setOptions(response?.DemoRequests);
            })
            .catch((error) => {
                console.error("Error fetching options:", error);
            });
    };

    const deleteHandler = (e) => {
        const id = e.currentTarget.getAttribute("id");
        let deleteOption = selectedOptions.filter(
            (item) => item.StudentID != id
        );
        setSelectedOptions(deleteOption);
    };

    const buttonHandler = (e) => {
        const id = e.currentTarget.getAttribute("id");
        let [selected] = options.filter((item) => item.StudentID == id);
        setOptions(options.filter((item) => item.StudentID != id));
        setSelectedOptions((prev) => [...prev, selected]);
    };

    async function submitHandler() {
        let body = selectedOptions.map((item) => {
            return {
                StudentID: item.StudentID,
                ParentID: item.ParentID,
                EnrollCourseID: item.EnrollCourseID,
                StudentName: item.StudentName,
            };
        });
        try {
            const batchDetails = await Services.AddStudentDemo(
                "POST",
                JSON.stringify({
                    ...data,
                    DemoStudents: selectedOptions,
                }),
                token
            );
            console.log(batchDetails);
            if (batchDetails.Status == 1) {
                setAddModal(false);
                getscheduledClasses();
                SuccessSwal("Success", batchDetails.Message);
            } else {
                warningSwal("Warning", batchDetails.Message);
            }
        } catch (err) {
            console.log(err);
        }
    }
    console.log(addModal);
    return (
        <div>
            <Modal
                isOpen={addModal}
                contentLabel="Modal"
                onRequestClose={() => {
                    setAddModal(false);
                }}
                className={Styles["modal"]}
                overlayClassName={Styles["overlay"]}>
                <div className={Styles["wrapper"]}>
                    <div className={Styles["Table"]}>
                        <div
                            className={Styles["students-select"]}
                            ref={dropDownRef}>
                            <input
                                type="text"
                                value={searchTerm}
                                placeholder="Search By Parent Mobile"
                                onChange={handleInputChange}
                                style={{ width: "98%" }}
                            />
                            <button
                                className={Styles["numbersearch"]}
                                onClick={handleSearch}
                                type="button">
                                Search
                            </button>
                            {options.length > 0 && (
                                <div className={Styles["students-options"]}>
                                    {options.length > 0 &&
                                        options.map((option) => (
                                            <div key={option.StudentID}>
                                                <button
                                                    id={option.StudentID}
                                                    type="button"
                                                    onClick={buttonHandler}>
                                                    {" "}
                                                    {option.StudentName}
                                                </button>
                                            </div>
                                        ))}
                                </div>
                            )}
                        </div>
                        <table>
                            <thead>
                                <tr>
                                    <th>Student Name</th>
                                    <th>Email</th>
                                    <th>Parent Name</th>
                                    <th>Parent Mobile</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {selectedOptions.length > 0 ? (
                                    selectedOptions.map((item) => (
                                        <tr>
                                            <td>{item.StudentName}</td>
                                            <td>{item.EmailID}</td>
                                            <td>{item.ParentName}</td>
                                            <td>{item.MobileNumber}</td>
                                            <td>
                                                <button
                                                    onClick={deleteHandler}
                                                    id={item.StudentID}>
                                                    <AiOutlineClose />
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td
                                            colSpan={5}
                                            style={{ textAlign: "center" }}>
                                            {" "}
                                            No Data Found
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <button
                        onClick={submitHandler}
                        disabled={selectedOptions.length == 0}>
                        Add
                    </button>
                </div>
            </Modal>
        </div>
    );
}

export default Add;
