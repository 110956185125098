import React, { useState } from "react";
import Layout from "Components/Layout";
import { Helmet } from "react-helmet";
import Styles from "./Payouts.module.css";
import { useForm } from "react-hook-form";
import ReactLoading from "react-loading";
import moment from "moment";
import { SuccessSwal, warningSwal } from "Util/Toast";
import { Services } from "../../Services";
import { useNavigate } from "react-router-dom";
function Payouts() {
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [getPayouts, setPayouts] = useState([]);
    const onSubmit = (data) => {
        const fromMoment = moment(data.FromDate);
        const toMoment = moment(data.ToDate);

        // Check if they are in the same month and year
        if (!fromMoment.isSame(toMoment, "month")) {
            warningSwal(
                "warning",
                "From Date and To Date should be of same month"
            );
            return;
        }
        const token = {
            Authorization: `token ${localStorage.getItem("token")}`,
        };
        setLoading(true);
        let body = { ...data, Month: fromMoment.format("MMMM") };
        Services.bulkpayouts("POST", JSON.stringify(body), token)
            .then((response) => {
                setLoading(false);
                if (response.Status === 1) {
                    SuccessSwal("Success", response.Message);
                    navigate(-1);
                } else if (response.Status === 0) {
                    setLoading(false);
                    warningSwal("Warning", response.Message);
                }
            })
            .catch((err) => {
                // alert(err);
                setLoading(false);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    return (
        <Layout Active={"bulk-payouts"}>
            {" "}
            <Helmet>
                <meta charSet="utf-8" />
                <title>Bulk Payouts</title>
            </Helmet>
            <div className={Styles["Container"]}>
                <div className={Styles["wrapper"]}>
                    <h3>Bulk Payouts</h3>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={Styles["form-control"]}>
                            <label htmlFor="First Name">
                                From Date <span className="important">*</span>
                            </label>
                            <input
                                type="date"
                                {...register("FromDate", {
                                    required: "this field is required",
                                })}
                            />
                            {errors.FromDate && (
                                <span>{errors.FromDate.message}</span>
                            )}
                        </div>
                        <div className={Styles["form-control"]}>
                            <label htmlFor="First Name">
                                To Date <span className="important">*</span>
                            </label>
                            <input
                                type="date"
                                {...register("ToDate", {
                                    required: "this field is required",
                                })}
                            />
                            {errors.ToDate && (
                                <span>{errors.ToDate.message}</span>
                            )}
                        </div>
                        <button disabled={loading}>
                            {" "}
                            {loading ? (
                                <ReactLoading
                                    color="green"
                                    type="spokes"
                                    height={30}
                                    width={30}
                                />
                            ) : (
                                "Submit"
                            )}
                        </button>
                    </form>
                </div>
            </div>
        </Layout>
    );
}

export default Payouts;
