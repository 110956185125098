import React, { useState } from "react";
import Layout from "../../Components/Layout";
import TutorClasses from "./index.module.css";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Services } from "../../Services";
import ReactLoading from "react-loading";
import { SuccessSwal, warningSwal } from "../../Util/Toast";
import Table from "./Table";
import moment from "moment";
window.Buffer = window.Buffer || require("buffer").Buffer;
function Addcategory() {
    const [loading, setLoading] = useState(false);
    const [Result, setResult] = useState([]);
    const [link, setLink] = useState("");
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const token = { Authorization: `token ${localStorage.getItem("token")}` };

    const onSubmit = (data) => {
        setLoading(true);
        if (data.FromDate > data.ToDate) {
            warningSwal("Warning", "Invalid Date Range");
            setLoading(false);
            return;
        }

        Services.AllReportsDownload("POST", JSON.stringify(data), token)
            .then((response) => {
                setLoading(false);
                if (response.Status === 1) {
                    // Navigate(-1);
                    // SuccessSwal("Category Added", response.Message);
                    // window.open(, "_blank");
                    setLink(response.CsvFile);
                    if (data?.Type == "All_Registrations") {
                        setResult(
                            response.Results?.map((item) => ({
                                "Parent Name": item?.ParentName,
                                "Student Name": item?.StudentName,
                                Mobile: item?.MobileNumber,
                                "Course Name": item?.CourseName,
                                "Payment Date":
                                    item?.PaymentDateTime !=
                                    "0000-00-00 00:00:00"
                                        ? moment(item?.PaymentDateTime).format(
                                              "DD-MM-YYYY"
                                          )
                                        : "NA",
                                "Lead Owner": item?.ClaimedByName,
                                "Price Paid": item?.PricePaid,
                                "Demo Taken By": item?.DemoTakenByName,
                                "Registration Date": moment(
                                    item?.CreatedAt
                                ).format("DD-MM-YYYY"),
                            }))
                        );
                    } else {
                        setResult(response.Results);
                    }
                } else if (response.Status === 0) {
                    setLoading(false);
                    warningSwal("Warning", response.Message);
                }
            })
            .catch((err) => {
                // alert(err);
                setLoading(false);
                alert("something went wrong please try again");
                console.log(err);
            });
    };

    return (
        <Layout Active={"Reports"}>
            <Helmet>
                <meta charSet="utf-8" />
                <title> Reports</title>
            </Helmet>
            <div className={TutorClasses["Container"]}>
                <div className={TutorClasses["wrapper"]}>
                    <h3>Reports</h3>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {/* <h3>Add Category</h3> */}
                        <div className={TutorClasses["form-control"]}>
                            <label htmlFor="First Name">
                                From Date <span className="important">*</span>
                            </label>
                            <input
                                type="date"
                                {...register("FromDate", {
                                    required: "this field is required",
                                })}
                            />
                            {errors.FromDate && (
                                <span>{errors.FromDate.message}</span>
                            )}
                        </div>
                        <div className={TutorClasses["form-control"]}>
                            <label htmlFor="First Name">
                                To Date <span className="important">*</span>
                            </label>
                            <input
                                type="date"
                                {...register("ToDate", {
                                    required: "this field is required",
                                })}
                            />
                            {errors.ToDate && (
                                <span>{errors.ToDate.message}</span>
                            )}
                        </div>
                        <div className={TutorClasses["form-control"]}>
                            <label htmlFor="First Name">
                                Type <span className="important">*</span>
                            </label>
                            <select
                                {...register("Type", {
                                    required: true,
                                    onChange: (e) => {
                                        setResult([]);
                                    },
                                })}>
                                <option value="TutorConversions">
                                    Tutor Conversions
                                </option>
                                <option value="SmClosures">SM Closures</option>
                                <option value="All_Registrations">
                                    All Registrations
                                </option>
                            </select>
                            {errors.Type && <span>This field is required</span>}
                        </div>

                        <button disabled={loading}>
                            {" "}
                            {loading ? (
                                <ReactLoading
                                    color="green"
                                    type="spokes"
                                    height={30}
                                    width={30}
                                />
                            ) : (
                                "Submit"
                            )}
                        </button>
                    </form>
                    {Result.length > 0 && (
                        <div
                            className={TutorClasses["downloadContent"]}
                            style={{ overflow: "auto" }}>
                            <Table data={Result} />
                            <button onClick={() => window.open(link, "_blank")}>
                                Export
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    );
}

export default Addcategory;
