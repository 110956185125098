import React, { useState } from "react";
import Modal from "react-modal";
import Styles from "./cancel.module.css";
import ReactLoading from "react-loading";
import { useForm } from "react-hook-form";
function Add({ completemodal, setModal, data, cancelClass }) {
    const [loading, setLoading] = useState(false);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setError,
        clearErrors,
        watch,
    } = useForm();

    // batch_Type one-to-one -1
    // batch_Type group -2
    // batch_Type group -3
    const inputData = watch();
    const types = [
        { type: "Tutor_On_Leave", reason: "Teacher denied taking the demo" },
        {
            type: "Parent_Requested_Reschedule",
            reason: "Parent Requested to Reschedule",
        },
        { type: "Public_Holiday", reason: "Public holiday" },
        { type: "Student_No_Show", reason: "Student no show" },
        {
            type: "Tutor_No_Show",
            reason: "Teacher No Show.",
        },
    ];
    async function onSubmit(formData) {
        //CancelReason
        //CancelType
        let body = { ...formData };

        if (formData.ActionType != "Others") {
            let reason = types.filter(
                (item) => item.type == formData.ActionType
            );
            console.log(reason);
            body = { ...body, ActionComments: reason[0].reason };
        } else if (formData.CancelType == "Others") {
            body = { ...body, ActionComments: formData?.ActionComments };
        } else {
            body = { ...body, ActionComments: "Less then 50% attendance" };
        }
        setLoading(true);
        await cancelClass(body);
        setLoading(false);
    }
    return (
        <div>
            <Modal
                isOpen={completemodal}
                contentLabel="Modal"
                onRequestClose={() => {
                    setModal(false);
                    reset({ CancelType: 0 });
                    clearErrors();
                }}
                className={Styles["modal"]}
                overlayClassName={Styles["overlay"]}>
                <div className={Styles["wrapper"]}>
                    <header>
                        <h3>Cancel Class</h3>
                    </header>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={Styles["form-control"]}>
                            <label htmlFor="Reason">Cancellation Reason</label>
                            <select
                                defaultValue={""}
                                {...register("ActionType", {
                                    required: "This is required",
                                })}>
                                <option value={0} disabled>
                                    Select Reason
                                </option>
                                {types.map((item) => (
                                    <option value={item.type}>
                                        {item.reason}
                                    </option>
                                ))}

                                <option value="Others">Others</option>
                            </select>
                            {errors.CancelType && (
                                <span style={{ color: "red" }}>
                                    {errors.ActionType.message}
                                </span>
                            )}
                        </div>
                        {inputData?.ActionType == "Others" && (
                            <div className={Styles["form-control"]}>
                                <label htmlFor=""> Reason</label>
                                <textarea
                                    {...register("ActionComments", {
                                        required: true,
                                    })}></textarea>
                            </div>
                        )}
                        {loading ? (
                            <div
                                style={{
                                    justifySelf: "center",
                                    marginTop: "1rem",
                                }}>
                                {" "}
                                <ReactLoading
                                    color="green"
                                    type="spokes"
                                    height={30}
                                    width={30}
                                />
                            </div>
                        ) : (
                            <div className={Styles["Control-btns"]}>
                                <button type="submit">Cancel Class</button>
                                <button
                                    type="button"
                                    onClick={() => {
                                        setModal(false);
                                        clearErrors();
                                        reset({ ActionType: 0 });
                                    }}>
                                    close
                                </button>
                            </div>
                        )}
                    </form>
                </div>
            </Modal>
        </div>
    );
}

export default Add;
