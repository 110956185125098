import Modal from "react-modal";
import React, { useEffect } from "react";
import Styles from "./reschedule.module.css";
import { useForm } from "react-hook-form";

import { Services } from "Services";
import { Error } from "Util/Toast";
import { AiOutlineClose } from "react-icons/ai";
import swal from "sweetalert";
import moment from "moment";
Modal.setAppElement("#root");

const DemoForm = ({ rescheduleModal, setRescheduleModal, data }) => {
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors },
    } = useForm();
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const backendDate = new Date(data.Scheduled_StartDate); // Date received from the backend
    const minDate = new Date(backendDate);
    const currDate = new Date();

    minDate.setDate(backendDate.getDate() - 2); // Date minus 2 days
    const effectiveMinDate = minDate > currDate ? minDate : currDate;
    const maxDate = new Date(backendDate);
    maxDate.setDate(backendDate.getDate() + 2);

    useEffect(() => {
        setValue(
            "ReScheduled_StartDate",
            data.Scheduled_StartDate.substr(0, 10)
        );
    }, []);

    // CLOSE FORM
    const closeModal = () => {
        setRescheduleModal(false);
        reset();
    };
    //Calculate End Time Based on Batch Duration
    const calculateScheduledEndTime = (
        startDate,
        startTime,
        additionalMinutes
    ) => {
        const scheduledStartDateTime = new Date(`${startDate} ${startTime}`);

        // Add additional minutes
        const scheduledEndDateTime = new Date(
            scheduledStartDateTime.getTime() + additionalMinutes * 60000
        );
        console.log(scheduledEndDateTime);
        // Format the result
        const scheduledEndDate =
            moment(scheduledEndDateTime).format("YYYY-MM-DD");
        console.log(scheduledEndDate);
        const scheduledEndTime = scheduledEndDateTime
            .toTimeString()
            .split(" ")[0];

        return { scheduledEndDate, scheduledEndTime };
    };

    // FORM SUBMIT
    const onSubmit = (formdata) => {
        // Required to send intial Start Date and parent Details (parentId,RM ID's) along with form Data
        const parentDetails = [];

        for (let i = 0; i < data?.Students?.length; i++) {
            let RM = [
                data?.Students[i]?.Lead_RM,
                data?.Students[i]?.Buddy_RM,
                data?.Students[i]?.SecondShift_RM_Evening,
                data?.Students[i]?.SecondShift_RM_Morning,
            ];
            let details = {
                ParentID: data?.Students[i].ParentID,
                StudentID: data?.Students[i].StudentID,
                RM: RM.filter((item) => item !== 0),
            };
            parentDetails.push(details);
        }
        const loginData = JSON.parse(localStorage.getItem("admindata"));
        let result = calculateScheduledEndTime(
            formdata.ReScheduled_StartDate,
            formdata.ReScheduled_StartTime,
            data.ClassDuration
        );
        let body = JSON.stringify({
            ...formdata,
            Scheduled_StartDate: data?.Scheduled_StartDate,
            parentDetails: parentDetails,
            BatchID: data.BatchID,
            BatchName: data.BatchName,
            AdminUserType: loginData.Type,
            CourseName: data?.CourseName,
            ScheduleCreatedBy: data?.ScheduleCreatedBy,
            ScheduleCreatedByType: data?.ScheduleCreatedByType,
            ReScheduled_EndTime: result.scheduledEndTime,
            ReScheduled_EndDate: result.scheduledEndDate,
            RequestType: 1,
            TutorID: data?.TutorID,
            ScheduleClassID: data?.ScheduleClassID,
            ZoomMeetingID: data?.ZoomMeetingID,
        });
        Services.Reschedule("PUT", body, token, data.id)
            .then((response) => {
                console.log(response);
                if (response.Status === 1) {
                    closeModal();
                    swal("Requested", response.Message, "success");
                    data.details(data?.id);
                } else if (response.Status === 0) {
                    Error(response.Message);
                }
            })
            .catch((err) => {
                console.log(err);
                Error("something went wrong please try again");
            });
    };

    return (
        <Modal
            isOpen={rescheduleModal}
            contentLabel="Modal"
            className={Styles["modal"]}
            onRequestClose={closeModal}
            overlayClassName={Styles["overlay"]}>
            <div className={Styles["form-wrapper"]}>
                <button onClick={closeModal}>
                    <AiOutlineClose />
                </button>
                <h2>Reschedule</h2>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={Styles["form-control"]}>
                        <label htmlFor="Date">
                            Reschedule Date <span className="important">*</span>
                        </label>
                        <div className={Styles["date-input-container"]}>
                            <input
                                className={Styles["date-input"]}
                                type="date"
                                {...register("ReScheduled_StartDate", {
                                    required: true,
                                })}
                                min={effectiveMinDate
                                    .toISOString()
                                    .substr(0, 10)}
                                max={maxDate?.toISOString().substr(0, 10)}
                                // min={new Date().toISOString().split("T")[0]}
                            />
                        </div>
                        {errors.ReScheduled_StartDate && (
                            <span className={Styles["error"]}>
                                This field is required
                            </span>
                        )}
                    </div>
                    <div className={Styles["form-control"]}>
                        <label htmlFor="">
                            Start Time <span className="important">*</span>
                        </label>
                        <div className={Styles["date-input-container"]}>
                            <input
                                className={Styles["date-input"]}
                                type="time"
                                {...register("ReScheduled_StartTime", {
                                    required: true,
                                })}
                            />
                        </div>
                        {errors.ReScheduled_StartTime && (
                            <span className={Styles["error"]}>
                                This field is required
                            </span>
                        )}
                    </div>
                    {/* <div className={Styles["form-control"]}>
                        <label htmlFor="">End Time</label>
                        <div className={Styles["date-input-container"]}>
                            <input
                                className={Styles["date-input"]}
                                type="time"
                                {...register("ReScheduled_EndTime", {
                                    required: true,
                                })}
                            />
                        </div>
                        {errors.ReScheduled_EndTime && (
                            <span className={Styles["error"]}>
                                This field is required
                            </span>
                        )}
                    </div> */}

                    <div className={Styles["buttons"]}>
                        <button type="submit" className={Styles["submit"]}>
                            Reschedule
                        </button>
                        <button
                            type="button"
                            onClick={closeModal}
                            className={Styles["close"]}>
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
        </Modal>
    );
};
export default DemoForm;
