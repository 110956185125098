import React, { useState, useEffect } from "react";
import Scheduleclasses from "./demo.module.css";
import Layout from "../../Components/Layout/index";
import { Helmet } from "react-helmet";
import { Services } from "../../Services";
import { toast } from "react-toastify";
import SkeletonLoad from "./TableLoad/load";
import Moment from "react-moment";
import useStore from "../../store";
import ReactPaginate from "react-paginate";
import moment from "moment";
import { useNavigate, useSearchParams } from "react-router-dom";
import { MdEdit } from "react-icons/md";
import { FaRegEye } from "react-icons/fa";
import { FaSearch } from "react-icons/fa";
import { useForm } from "react-hook-form";
import Select from "react-select";
import { warningSwal } from "Util/Toast";
function Tutor() {
    const RecordCount = useStore((item) => item.NoOfRecords);
    const Navigate = useNavigate();
    const [showPaginate, setShowPagiate] = useState(true);
    const [forcepage, setforcepage] = useState(0);
    const [tutors, setTutors] = useState([]);
    const [selectedTutor, setSelectedTutor] = useState(null);
    const [selectedcourse, setSelectedCourse] = useState(null);
    const [selectedOwner, setSelectedOwner] = useState(null);
    const [tutorSearchString, setTutorSearchString] = useState(null);
    const [apiBody, setApiBody] = useState({});
    const [tutorSearchLoad, setTutorSearchLoad] = useState(false);
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors },
    } = useForm();

    const [searchparams, setSearchParams] = useSearchParams();
    const [Sales, setSales] = useState([]);
    const [Active, setActive] = useState("1");
    const [loading, setLoading] = useState(true);
    const [search, SetSearch] = useState(false);
    const [pageCount, setpageCount] = useState(1);
    const [Course, setCourse] = useState([]);

    const [admindata, setadmindata] = useState(
        JSON.parse(localStorage.getItem("admindata")) || { Type: 1 }
    );
    const [count, setCount] = useState(0);
    const [data, setData] = useState([]);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    useEffect(() => {
        const debouncedSearch = setTimeout(async () => {
            if (
                typeof tutorSearchString == "string" &&
                tutorSearchString.length > 0
            ) {
                setTutorSearchLoad(true);

                try {
                    const data = await Services.searchActiveTutor(
                        "POST",
                        JSON.stringify({ SearchString: tutorSearchString }),
                        token,
                        selectedTutor
                    );
                    const formattedOptions = data.Tutors.map((tutor) => ({
                        label: `${tutor.FirstName} ${tutor.LastName}`,
                        value: tutor.TutorID,
                    }));
                    setTutors(formattedOptions);
                } catch (error) {
                    console.error("Error fetching options:", error);
                } finally {
                    setTutorSearchLoad(false);
                }
            }
        }, 300);

        return () => clearTimeout(debouncedSearch);
    }, [tutorSearchString]);
    const onTutorSelect = (option) => {
        console.log(option);
        if (option) {
            setSelectedTutor(option);
        }
    };
    const handleChange = (selectedOption) => {
        setTutorSearchString(selectedOption);
    };
    const TutorClearHandler = () => {
        setTutorSearchString(null);
        setSelectedTutor(null);
        let body = { ...apiBody };
        delete body.TutorID;
        setApiBody(body);
        Get_Classes(0, Active, body);
    };
    const CourseClear = () => {
        setSelectedCourse(null);
        // setSelectedTutor(null);
        let body = { ...apiBody };
        delete body.CourseID;
        setApiBody(body);
        Get_Classes(0, Active, body);
    };
    const OwnerClear = () => {
        setSelectedOwner(null);
        // setSelectedTutor(null);
        let body = { ...apiBody };
        delete body.AdminUserID;
        setApiBody(body);
        Get_Classes(0, Active, body);
    };
    const Get_Classes = (skip, status, filter) => {
        let body = {
            DemoClassStatus: status,
            toSkip: skip,
            ...filter,
        };
        setLoading(true);
        Services.getDemoClasses(
            "POST",
            JSON.stringify(body),
            token,
            skip,
            status
        )
            .then((response) => {
                setTimeout(() => {
                    setLoading(false);
                }, 200);
                if (response.Status === 1) {
                    setData(response.DemoRequests);
                    setCount(response?.Count);
                    if (
                        response.DemoRequests.length == 0 ||
                        response.Count <= RecordCount
                    ) {
                        setShowPagiate(false);
                    } else {
                        if (!showPaginate) {
                            setShowPagiate(true);
                        }
                        setpageCount(
                            Math.ceil(
                                Number(response?.Count) / Number(RecordCount)
                            )
                        );
                    }
                } else if (response.Status === 0) {
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                setLoading(false);
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    const getManagers = () => {
        Services.list_salesmanger("GET", null, token)
            .then((response) => {
                setTimeout(() => {
                    setLoading(false);
                }, 200);
                if (response.Status === 1) {
                    setSales(
                        response?.AdminUsers?.map((item) => ({
                            label: `${item?.FirstName} ${item?.LastName}`,
                            value: item?.AdminUserID,
                        }))
                    );
                } else if (response.Status === 0) {
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                setLoading(false);
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    useEffect(() => {
        let activeTab = searchparams.get("tab");
        let page = searchparams.get("page");
        setforcepage(Number(page) - 1);
        let prevData = localStorage.getItem("demo_class_filter");

        if (prevData != "undefined") {
            prevData = JSON.parse(prevData);
            if (prevData?.FromDate) {
                setValue("FromDate", prevData?.FromDate);
            }
            if (prevData?.ToDate) {
                setValue("ToDate", prevData?.ToDate);
            }
            if (prevData?.MobileNumber) {
                setValue("MobileNumber", prevData?.MobileNumber);
            }
            if (prevData?.AdminUserID) {
                console.log("sss");
                setSelectedOwner({
                    value: prevData?.AdminUserID,
                    label: prevData?.AdminName,
                });

                setSales([
                    {
                        value: prevData?.AdminUserID,
                        label: prevData?.AdminName,
                    },
                ]);
            }
            if (prevData?.TutorID) {
                setTutors([
                    {
                        value: prevData?.TutorID,
                        label: prevData?.TutorName,
                    },
                ]);

                setSelectedTutor({
                    value: prevData?.TutorID,
                    label: prevData?.TutorName,
                });
            }
            if (prevData?.CourseID) {
                setSelectedCourse([
                    {
                        value: prevData?.CourseID,
                        label: prevData?.CourseName,
                    },
                ]);

                setCourse({
                    value: prevData?.CourseID,
                    label: prevData?.CourseName,
                });
            }
        }
        setApiBody(prevData != "undefined" ? prevData : {});
        if (activeTab) {
            Get_Classes(
                (Number(page) - 1) * RecordCount,
                activeTab,
                prevData != "undefined" ? prevData : {}
            );
            setActive(activeTab);
        } else {
            Get_Classes(
                (Number(page) - 1) * RecordCount,
                activeTab,
                prevData != "undefined" ? prevData : {}
            );
        }
        getManagers();
        Services.getCourses("GET", null, token)
            .then((response) => {
                console.log(response);
                setTimeout(() => {
                    setLoading(false);
                }, 200);
                if (response.Status === 1) {
                    setCourse(
                        response.Courses?.map((item) => ({
                            label: item?.CourseName,
                            value: item?.CourseID,
                        }))
                    );
                } else if (response.Status === 0) {
                    warningSwal(response.Message);
                }
            })
            .catch((err) => {
                setLoading(false);
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
        // eslint-disable-next-line
    }, []);
    // eslint-disable-next-line
    const NavHandler = (e) => {
        let Type = e.currentTarget.getAttribute("btn-type");
        setSearchParams({ tab: Type, page: 1 });
        setActive(Type);
        setLoading(true);
        Get_Classes(0, Type, apiBody);
    };
    const detailsHandler = (e) => {
        let id = e.currentTarget.getAttribute("id");
        let name = e.currentTarget.getAttribute("name");
        Navigate(`/scheduledClasses/Details?scheduleID=${id}`);
        localStorage.setItem("name", name);
    };
    const handlePageChange = (selectedObject) => {
        console.log(selectedObject);
        // setcurrentPage(selectedObject.selected);
        // handleFetch();
        setSearchParams({ tab: Active, page: selectedObject.selected + 1 });
        window.scrollTo({ top: 10, behavior: "instant" });
        setforcepage(selectedObject.selected);
        Get_Classes(selectedObject.selected * RecordCount, Active, apiBody);
        setLoading(true);
    };
    const onSubmit = (data) => {
        let body;

        if (data.MobileNumber) {
            body = {
                MobileNumber: data.MobileNumber,
                whether_Mobile_filter: 1,
            };
        }
        if (data.FromDate || data.ToDate) {
            if (data.FromDate) {
                if (!data.ToDate) {
                    body = {
                        ...body,
                        FromDate: data.FromDate,
                        ToDate: data?.FromDate,
                    };
                } else {
                    body = {
                        ...body,
                        FromDate: data.FromDate,
                        ToDate: data?.ToDate,
                    };
                }
            }
            if (data.ToDate) {
                if (!data.FromDate) {
                    body = {
                        ...body,
                        FromDate: data.ToDate,
                        ToDate: data?.ToDate,
                    };
                } else {
                    body = {
                        ...body,
                        FromDate: data.FromDate,
                        ToDate: data?.ToDate,
                    };
                }
            }
        }
        if (selectedOwner) {
            SetSearch(true);
            body = {
                ...body,
                AdminUserID: selectedOwner?.value,
                AdminName: selectedOwner.label,
            };
        }
        console.log(selectedTutor);
        if (selectedTutor) {
            body = {
                ...body,
                TutorID: selectedTutor?.value,
                TutorName: selectedTutor?.label,
            };
        }
        if (selectedcourse) {
            body = {
                ...body,
                CourseID: selectedcourse.value,
                CourseName: selectedcourse.label,
            };
        }
        setApiBody(body);
        setforcepage(0);
        setLoading(true);
        setSearchParams({ tab: Active, page: 1 });
        localStorage.setItem("demo_class_filter", JSON.stringify(body));
        Get_Classes(0, Active, body);
    };
    const clearHandler = () => {
        localStorage.removeItem("demo_class_filter");
        reset();
        setSelectedCourse(null);
        setSelectedOwner(null);
        setSelectedTutor(null);
        setApiBody(null);
        setforcepage(0);
        Get_Classes(0, Active);
    };
    return (
        <Layout Active={"Demo Classes"}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Scheduled Classes</title>
            </Helmet>
            <div className={Scheduleclasses["Container"]}>
                <div className={Scheduleclasses["control-btns"]}>
                    <button
                        className={Scheduleclasses["button"]}
                        onClick={() => {
                            Navigate("/add-demo-class");
                        }}>
                        {" "}
                        Add Demo Class
                    </button>
                </div>
                <form
                    className={Scheduleclasses["Filter"]}
                    onSubmit={handleSubmit(onSubmit)}>
                    <div className={Scheduleclasses["form_control"]}>
                        <label htmlFor="">Mobile Number</label>
                        <div>
                            <FaSearch
                                className={Scheduleclasses["search-icon"]}
                            />
                            <input
                                {...register("MobileNumber", {
                                    required: false,
                                })}
                                type="number"
                                placeholder="Mobile Number"
                            />
                        </div>
                    </div>
                    <div className={Scheduleclasses["form_control"]}>
                        <label htmlFor="">From Date</label>
                        <input
                            style={{ textTransform: "uppercase" }}
                            {...register("FromDate", { required: false })}
                            type="date"
                        />
                    </div>
                    <div className={Scheduleclasses["form_control"]}>
                        <label htmlFor="">To Date</label>
                        <input
                            style={{ textTransform: "uppercase" }}
                            {...register("ToDate", { required: false })}
                            type="date"
                        />
                    </div>
                    <div className={Scheduleclasses["form-control"]}>
                        <label htmlFor="">Lead Owner</label>
                        <Select
                            options={Sales}
                            onChange={(value) => setSelectedOwner(value)}
                            value={selectedOwner}
                            noOptionsMessage={() => "No Lead owner Found"}
                            classNames={Scheduleclasses["select"]}
                            styles={{
                                // Set the width to 100%
                                control: (provided) => ({
                                    ...provided,
                                    width: "14rem",
                                    borderRadius: "10px",
                                }),
                            }}
                            isClearable
                            components={{
                                ClearIndicator: () => (
                                    <button
                                        onClick={() => setSelectedOwner(null)}>
                                        Clear
                                    </button>
                                ),
                            }}
                        />
                    </div>
                    <div className={Scheduleclasses["form-control"]}>
                        <label htmlFor="">Course </label>
                        <Select
                            options={Course}
                            onChange={(value) => setSelectedCourse(value)}
                            value={selectedcourse}
                            noOptionsMessage={() => "No Courses Found"}
                            classNames={Scheduleclasses["select"]}
                            styles={{
                                // Set the width to 100%
                                control: (provided) => ({
                                    ...provided,
                                    width: "14rem",
                                    borderRadius: "10px",
                                }),
                            }}
                            isClearable
                            components={{
                                ClearIndicator: () => (
                                    <button
                                        onClick={() => setSelectedCourse(null)}>
                                        Clear
                                    </button>
                                ),
                            }}
                        />
                    </div>
                    <div className={Scheduleclasses["form-control"]}>
                        <label htmlFor="Tutor">Tutor</label>
                        <Select
                            options={tutors}
                            onInputChange={handleChange}
                            onChange={onTutorSelect}
                            value={selectedTutor}
                            isLoading={tutorSearchLoad}
                            placeholder="Search Tutor"
                            noOptionsMessage={() => "No Tutor Found"}
                            classNames={Scheduleclasses["select"]}
                            styles={{
                                // Set the width to 100%
                                control: (provided) => ({
                                    ...provided,
                                    width: "14rem",
                                    borderRadius: "10px",
                                }),
                            }}
                            isClearable
                            components={{
                                ClearIndicator: () => (
                                    <button
                                        onClick={() => setSelectedTutor(null)}>
                                        Clear
                                    </button>
                                ),
                            }}
                        />
                    </div>
                    <button>Submit</button>
                    <button
                        style={{
                            border: "1px solid #0077b6",
                            backgroundColor: "white",
                            color: "#0077b6",
                        }}
                        onClick={clearHandler}>
                        Clear
                    </button>
                </form>
                {((search && Active === "2") ||
                    (Active == "2" && admindata.Type == 5)) && (
                    <p>Total Completed Classes:{count}</p>
                )}
                <div className={Scheduleclasses["Tutor"]}>
                    <h3>Classes</h3>
                    <div className={Scheduleclasses["Navigator"]}>
                        <button
                            btn-type="1"
                            className={
                                Active === "1" ? Scheduleclasses["active"] : ""
                            }
                            onClick={NavHandler}>
                            Demo scheduled
                        </button>
                        <button
                            btn-type="2"
                            className={
                                Active === "2" ? Scheduleclasses["active"] : ""
                            }
                            onClick={NavHandler}>
                            Completed
                        </button>
                        <button
                            btn-type="3"
                            className={
                                Active === "3" ? Scheduleclasses["active"] : ""
                            }
                            onClick={NavHandler}>
                            Cancelled
                        </button>
                        <button
                            btn-type="4"
                            className={
                                Active === "4" ? Scheduleclasses["active"] : ""
                            }
                            onClick={NavHandler}>
                            Draft
                        </button>
                    </div>

                    {loading ? (
                        <SkeletonLoad count={3} />
                    ) : data.length > 0 ? (
                        <div className={Scheduleclasses["Table"]}>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Start Date Time</th>
                                        {/*  <th>End Date Time</th> */}
                                        <th>Batch Size</th>
                                        <th>Tutor</th>
                                        <th>Course Name</th>
                                        <th>Created By</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item) => {
                                        const timeStr = item?.DemoStartTime;

                                        // Combine date and time
                                        const dateTime = moment(
                                            item?.DemoStartDate
                                        ).set({
                                            hour: moment(
                                                timeStr,
                                                "HH:mm:ss"
                                            ).hours(),
                                            minute: moment(
                                                timeStr,
                                                "HH:mm:ss"
                                            ).minutes(),
                                            second: moment(
                                                timeStr,
                                                "HH:mm:ss"
                                            ).seconds(),
                                        });
                                        // const EndtimeStr = item?.DemoEndTime;

                                        // Combine date and time
                                        /*   const enddateTime = moment(
                                            item?.DemoEndDate
                                        ).set({
                                            hour: moment(
                                                EndtimeStr,
                                                "HH:mm:ss"
                                            ).hours(),
                                            minute: moment(
                                                timeStr,
                                                "HH:mm:ss"
                                            ).minutes(),
                                            second: moment(
                                                timeStr,
                                                "HH:mm:ss"
                                            ).seconds(),
                                        }); */
                                        return (
                                            <tr name={item.TutorName}>
                                                {/* <td>{item.DemoClassID}</td> */}
                                                <td>
                                                    <Moment format="hh:mm A">
                                                        {dateTime}
                                                    </Moment>
                                                    <br />
                                                    <Moment format="DD-MM-YYYY">
                                                        {dateTime}
                                                    </Moment>
                                                </td>
                                                {/*   <td>
                                                    <Moment format="hh:mm A">
                                                        {enddateTime}
                                                    </Moment>
                                                    <br />
                                                    <Moment format="DD-MM-YYYY">
                                                        {enddateTime}
                                                    </Moment>
                                                </td> */}
                                                {<td>{item.BatchSize}</td>}

                                                <td>{item.TutorName}</td>
                                                <td>{item.CourseName}</td>
                                                <td>{item?.CreatedBy}</td>
                                                <td>
                                                    <div
                                                        className={
                                                            Scheduleclasses[
                                                                "act-btns"
                                                            ]
                                                        }>
                                                        <button
                                                            onClick={() => {
                                                                Navigate(
                                                                    `/add-demo-class?id=${item.DemoClassID}`
                                                                );
                                                            }}>
                                                            <MdEdit />
                                                        </button>
                                                        <button
                                                            onClick={() => {
                                                                Navigate(
                                                                    `/demo-class-details?id=${item.DemoClassID}`
                                                                );
                                                            }}>
                                                            <FaRegEye />
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <div>
                            {" "}
                            <p>No Records Found</p>
                        </div>
                    )}
                    {showPaginate && (
                        <div
                            style={{
                                justifySelf: "right",
                            }}>
                            <ReactPaginate
                                pageCount={pageCount}
                                // pageRange={2}
                                forcePage={forcepage}
                                marginPagesDisplayed={2}
                                onPageChange={handlePageChange}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                containerClassName={"pagination"}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                previousClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                activeClassName={"active"}
                            />
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    );
}

export default Tutor;
