import React, { useState, useEffect } from "react";
import Styles from "./index.module.css";
import Layout from "Components/Layout";
import Mainloader from "Components/loader/loader";
import { useForm, Controller } from "react-hook-form";
import ReactLoading from "react-loading";
import { Services } from "Services";
import { warningSwal, SuccessSwal } from "Util/Toast";
import { useParams, useSearchParams } from "react-router-dom";
import moment from "moment";
import { Country } from "country-state-city";
import { PhoneNumberValidate } from "Components/validators";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import TimePicker from "rc-time-picker";
import ReactFlagsSelect from "react-flags-select";
function AddDemo() {
    const Navigate = useNavigate();
    const [searchparams] = useSearchParams();
    let Countries = Country.getAllCountries();
    const [loading, setLoading] = useState(false);
    const [mainload, setMainLoad] = useState(false);
    const [phoneNumber, setphoneNumber] = useState("");
    const [courses, setCourses] = useState([]);

    const [selectedcountry, setSelectedCountry] = useState({
        PhoneCode: "91",
        Country: "IN",
        Name: "India",
    });
    const {
        register,
        handleSubmit,
        reset,
        control,
        setValue,
        clearErrors,
        formState: { errors },
    } = useForm();
    const token = { Authorization: `token ${localStorage.getItem("token")}` };

    const onSubmit = (data) => {
        setLoading(true);
        let body = JSON.stringify({
            ...data,
            MobileNumber: `${data.MobileNumber}`,
            CountryCode: `+${selectedcountry.PhoneCode}`,
            StudentAge:
                new Date().getFullYear() - new Date(data.DOB).getFullYear(),
        });
        if (searchparams.get("id")) {
            Services.updateDemoRequest(
                "PUT",
                body,
                token,
                searchparams.get("id")
            )
                .then((response) => {
                    console.log(response);
                    if (response.Status === 1) {
                        SuccessSwal("Success", response.Message);
                        Navigate(-1);
                    } else if (response.Status === 0) {
                        setLoading(false);
                        warningSwal("Warning", response.Message);
                    }
                })
                .catch((err) => {
                    setLoading(false);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        } else {
            Services.AddDemoRequest("POST", body, token)
                .then((response) => {
                    console.log(response);
                    if (response.Status === 1) {
                        SuccessSwal("Success", response.Message);
                        Navigate(-1);
                    } else if (response.Status === 0) {
                        setLoading(false);
                        warningSwal("Warning", response.Message);
                    }
                })
                .catch((err) => {
                    setLoading(false);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        }
    };
    const blurHandler = (e) => {
        e.preventDefault();
        e.target.blur();

        return false;
    };
    const phoneNumberCheck = (e) => {
        setphoneNumber(e.target.value);
    };
    useEffect(() => {
        FetchDropDowns();
    }, []);
    const FetchDropDowns = async () => {
        setLoading(true);
        try {
            const [response1] = await Promise.all([
                Services.getCourses("GET", null, token),
            ]);

            setCourses(response1.Courses);
            if (searchparams.get("id")) {
                setTimeout(() => {
                    reset(JSON.parse(localStorage.getItem("demo")));
                    setLoading(false);
                }, 500);
            } else {
                setLoading(false);
            }
        } catch (error) {
            alert("something went wrong please try again");
            console.error("Error fetching data:", error);
        }
    };
    const phoneChangeHandler = (data) => {
        console.log(data);
        const selectedCountry = Countries.filter(
            (item) => item.isoCode == data
        );
        if (selectedCountry.length > 0) {
            console.log(selectedCountry);
            setSelectedCountry({
                PhoneCode: selectedCountry[0].phonecode,
                Country: selectedCountry[0].isoCode,
                Name: selectedCountry[0].name,
            });
        }
    };
    const NullCheck_Names = (value) => {
        if (!value) {
            return "This field is required";
        }

        // Check for alphabetic characters without numbers or special characters
        if (/^[a-zA-Z ]+$/.test(value)) {
            return true; // Validation passed
        } else {
            return "Only alphabetic characters are allowed";
        }
    };
    const backButton = () => {
        Navigate(-1);
    };
    return (
        <Layout Active={"Demo Requests"}>
            <div className={Styles["Container"]}>
                <button onClick={backButton} className={Styles["back"]}>
                    Back
                </button>
                <div className={Styles["wrapper"]}>
                    <h3>
                        {searchparams.get("id") ? "Update" : "Add"} Demo request
                    </h3>
                    {mainload ? (
                        <Mainloader />
                    ) : (
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className={Styles["form-control"]}>
                                <label htmlFor="">
                                    Parent Name<span>*</span>
                                </label>
                                <input
                                    className={
                                        errors?.ParentName
                                            ? Styles["error"]
                                            : ""
                                    }
                                    type="text"
                                    placeholder="Parent Name"
                                    {...register("ParentName", {
                                        required: " This field is required",
                                        validate: NullCheck_Names,
                                    })}
                                />
                                {errors.ParentName && (
                                    <span className={Styles["error-msg"]}>
                                        {errors.ParentName.message}
                                    </span>
                                )}
                            </div>
                            {!searchparams.get("id") && (
                                <div className={Styles["form-control"]}>
                                    <label htmlFor="ContactNumber">
                                        Phone Number <span>*</span>
                                    </label>
                                    <div className={Styles["select-code"]}>
                                        <input
                                            type="number"
                                            style={{ textIndent: "5rem" }}
                                            onWheel={blurHandler}
                                            onScroll={blurHandler}
                                            {...register("MobileNumber", {
                                                required:
                                                    "this field is required",
                                                onChange: (e) => {
                                                    phoneNumberCheck(e);
                                                },
                                                validate: PhoneNumberValidate,
                                            })}
                                        />
                                        <div>
                                            <ReactFlagsSelect
                                                searchable
                                                selected={
                                                    selectedcountry.Country
                                                }
                                                showSelectedLabel={false}
                                                onSelect={phoneChangeHandler}
                                                fullWidth={false}
                                                // customLabels={customLabels}
                                                placeholder=""
                                                className={Styles["select-btn"]}
                                            />
                                        </div>
                                    </div>
                                    {errors.MobileNumber && (
                                        <span>
                                            {errors.MobileNumber.message}
                                        </span>
                                    )}
                                </div>
                            )}
                            <div className={Styles["form-control"]}>
                                <label htmlFor="ContactNumber">Email</label>
                                <input
                                    className={
                                        errors?.EmailID ? Styles["error"] : ""
                                    }
                                    type="email"
                                    placeholder="Your Email"
                                    {...register("EmailID", {
                                        required: false,
                                    })}
                                />
                                {errors.EmailID && (
                                    <span className={Styles["error-msg"]}>
                                        This field is required
                                    </span>
                                )}
                            </div>
                            <div className={Styles["form-control"]}>
                                <label htmlFor="">
                                    Child Name<span>*</span>
                                </label>
                                <input
                                    className={
                                        errors?.StudentName
                                            ? Styles["error"]
                                            : ""
                                    }
                                    type="text"
                                    placeholder="Child Name"
                                    {...register("StudentName", {
                                        required: " This field is required",
                                        validate: NullCheck_Names,
                                    })}
                                />
                                {errors.StudentName && (
                                    <span className={Styles["error-msg"]}>
                                        {errors.StudentName.message}
                                    </span>
                                )}
                            </div>
                            <div className={Styles["form-control"]}>
                                <label htmlFor="">Years</label>
                                <input
                                    className={
                                        errors?.Age ? Styles["error"] : ""
                                    }
                                    type={"number"}
                                    placeholder="Child's Birth Date"
                                    {...register("Age", {
                                        required: false,
                                    })}
                                />
                                {errors.Age && (
                                    <span className={Styles["error-msg"]}>
                                        This field is required
                                    </span>
                                )}
                            </div>
                            <div className={Styles["form-control"]}>
                                <label htmlFor="">Month</label>
                                <input
                                    className={
                                        errors?.Months ? Styles["error"] : ""
                                    }
                                    type={"number"}
                                    // placeholder="Child'"
                                    {...register("Months", {
                                        required: false,
                                    })}
                                />
                                {errors.Months && (
                                    <span className={Styles["error-msg"]}>
                                        This field is required
                                    </span>
                                )}
                            </div>

                            <div className={Styles["form-control"]}>
                                <label htmlFor="Last Name">Course</label>
                                <select
                                    defaultValue={""}
                                    {...register("CourseID", {
                                        required: false,
                                    })}>
                                    <option value="">Select Course</option>
                                    {courses?.map((item) => (
                                        <option value={item.CourseID}>
                                            {item.CourseName}
                                        </option>
                                    ))}
                                </select>
                                {errors.CourseID && (
                                    <span>This field is required</span>
                                )}
                            </div>
                            <button disabled={loading}>
                                {" "}
                                {loading ? (
                                    <ReactLoading
                                        color="green"
                                        type="spokes"
                                        height={30}
                                        width={30}
                                    />
                                ) : (
                                    "Submit"
                                )}
                            </button>
                        </form>
                    )}
                </div>
            </div>
        </Layout>
    );
}

export default AddDemo;
