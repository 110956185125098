import React, { useEffect, useState } from "react";
import Layout from "Components/Layout";
import { Helmet } from "react-helmet";
import Styles from "./Payouts.module.css";
import { useForm } from "react-hook-form";
import ReactLoading from "react-loading";
import moment from "moment";
import Skeleton from "react-loading-skeleton";

import Moment from "react-moment";
import { warningSwal } from "Util/Toast";
import { Services } from "../../Services";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
function Payouts() {
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();
    const [loading, setLoading] = useState(true);
    const [SearchParams] = useSearchParams();
    const [Payouts, setPayouts] = useState([{ AmountToPay: 0, PayoutID: 0 }]);
    const [Earnings, setEarnings] = useState([]);
    const navigate = useNavigate();
    const token = {
        Authorization: `token ${localStorage.getItem("token")}`,
    };
    useEffect(() => {
        Services.PayoutDetails(
            "POST",
            JSON.stringify({ PayoutID: SearchParams.get("id") }),
            token
        )
            .then((response) => {
                setLoading(false);
                if (response.Status === 1) {
                    setPayouts(response?.Get_Payout_Earnings);
                    if (response?.Get_Payout_Earnings.length > 0) {
                        setEarnings(response?.Get_Payout_Earnings[0]?.Earnings);
                    }
                } else if (response.Status === 0) {
                    setLoading(false);
                    warningSwal("Warning", response.Message);
                }
            })
            .catch((err) => {
                // alert(err);
                setLoading(false);
                alert("something went wrong please try again");
                console.log(err);
            });
    }, []);
    return (
        <Layout Active={"bulk-payouts"}>
            {" "}
            <Helmet>
                <meta charSet="utf-8" />
                <title>Bulk Payouts</title>
            </Helmet>
            <div className={Styles["Container"]}>
                <div className={Styles["wrapper"]}>
                    <h3>Payout Details</h3>
                    <div className={Styles["head"]}>
                        <p>
                            Payout ID: <b>{Payouts[0]?.PayoutID}</b>
                        </p>
                        <p>
                            Payout Amount : <b>₹ {Payouts[0]?.AmountToPay}</b>
                        </p>
                    </div>
                    <div className={Styles["Table"]}>
                        <table>
                            <thead>
                                <tr>
                                    <th>Class Date</th>
                                    <th>Class Time</th>
                                    <th>Duration</th>
                                    <th>Batch Name</th>
                                    <th>Type</th>
                                    <th>Credit</th>
                                    <th> Debit</th>
                                </tr>
                            </thead>
                            {loading ? (
                                <tbody>
                                    {[1, 1].map((item) => (
                                        <tr className={Styles["loading"]}>
                                            <td>
                                                <Skeleton />
                                            </td>
                                            <td>
                                                <Skeleton />
                                            </td>

                                            <td>
                                                <Skeleton />
                                            </td>
                                            <td>
                                                <Skeleton />
                                            </td>
                                            <td>
                                                <Skeleton />
                                            </td>
                                            <td>
                                                <Skeleton />
                                            </td>
                                            <td>
                                                <Skeleton />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            ) : (
                                <tbody>
                                    {Earnings?.length > 0 ? (
                                        Earnings?.map((item) => {
                                            return (
                                                <tr>
                                                    <td>
                                                        <Moment format="DD-MM-YYYY">
                                                            {
                                                                new Date(
                                                                    item.Scheduled_StartDate
                                                                )
                                                            }
                                                        </Moment>
                                                    </td>
                                                    <td>
                                                        {
                                                            item.Scheduled_StartTime
                                                        }
                                                    </td>

                                                    <td>
                                                        {item.DurationInMinutes}{" "}
                                                        Min
                                                    </td>
                                                    <td
                                                        style={{
                                                            maxWidth: "12rem",
                                                            wordBreak:
                                                                "break-word",
                                                        }}>
                                                        {item.BatchName}
                                                    </td>
                                                    <td>
                                                        {item.EarningType ===
                                                            "Lesson_Cancel" &&
                                                            "Lesson Cancel"}
                                                        {item.EarningType ===
                                                            "Lesson_Complete" &&
                                                            "Lesson Complete"}
                                                        {/* <span
                                                    className={
                                                        item.PayoutStatus === 1
                                                            ? Styles[
                                                                  "paid"
                                                              ]
                                                            : Styles[
                                                                  "unpaid"
                                                              ]
                                                    }>
                                                    {item.PayoutStatus === 1
                                                        ? "paid"
                                                        : "pending"}
                                                </span>{" "} */}
                                                    </td>
                                                    <td
                                                        style={{
                                                            color:
                                                                String(
                                                                    item.Credit_Or_Debit
                                                                ).toLowerCase() ===
                                                                "DEBIT".toLowerCase()
                                                                    ? "red"
                                                                    : "green",
                                                        }}>
                                                        {item.Credit_Or_Debit !=
                                                        "DEBIT"
                                                            ? isNaN(item.Amount)
                                                                ? 0.0
                                                                : `₹ ${Number(
                                                                      item.Amount
                                                                  ).toFixed(2)}`
                                                            : "-"}
                                                    </td>
                                                    <td
                                                        style={{
                                                            color:
                                                                String(
                                                                    item.Credit_Or_Debit
                                                                ).toLowerCase() ===
                                                                "DEBIT".toLowerCase()
                                                                    ? "red"
                                                                    : "green",
                                                        }}>
                                                        {item.Credit_Or_Debit ==
                                                        "DEBIT"
                                                            ? isNaN(item.Amount)
                                                                ? 0.0
                                                                : `₹ ${Number(
                                                                      item.Amount
                                                                  ).toFixed(2)}`
                                                            : "-"}
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <tr>
                                            <td
                                                colSpan={8}
                                                style={{
                                                    textAlign: "center",
                                                }}>
                                                No Record Found
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            )}
                        </table>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default Payouts;
