import React, { useState, useEffect, useRef } from "react";
import detailsClasses from "./details.module.css";
import { MdEdit } from "react-icons/md";

import { Services } from "../../../Services";
import { useSearchParams } from "react-router-dom";
import Layout from "../../../Components/Layout";
import Mainloader from "../../../Components/loader/loader";
import moment from "moment";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { BiPlus } from "react-icons/bi";
import TableLoad from "../../scheduledClasses/TableLoad/load";
import Moment from "react-moment";
import { AiFillSetting, AiOutlineEdit } from "react-icons/ai";
import swal from "sweetalert";
import { MdDelete } from "react-icons/md";
import Modal from "../addstudents/add";
import { NavLink } from "react-router-dom";
import { SuccessSwal, warningSwal } from "../../../Util/Toast";
import CompleteModal from "../completeClass/Complete";
import { AccessKeys } from "../../../Routes/config";
import { AccessControl } from "../../../Util/AccessControl";
import Swal from "sweetalert";
import UpdateTutor from "./updateTutor";
function Details() {
    const Navigate = useNavigate();
    const [params] = useSearchParams();
    const priceref = useRef();
    const [batchLoad, setBatchLoad] = useState(true);
    const [complete, setComplete] = useState(false);
    const [loading, setLoading] = useState(true);
    const [updatetutor, setupdatetutor] = useState(false);
    const [load, setload] = useState(false);
    const [addModal, setAddModal] = useState(false);
    const [editableId, setEditableId] = useState(null);
    const [classes, setClasses] = useState([]);
    let [data, setData] = useState({});
    const [price, setPrice] = useState(0);
    const [ActiveStudents, setActiveStudents] = useState([]);
    const [classCount, setClassesCount] = useState([]);
    const [Tab, setTab] = useState("Active");
    const [editclass, setEditClass] = useState(false);
    const inputref = useRef();

    let color;
    const token = { Authorization: `token ${localStorage.getItem("token")}` };

    const loginData = JSON.parse(localStorage.getItem("admindata"));
    async function getBatchDetails(id) {
        try {
            const batchDetails = await Services.getbatchDetails(
                "GET",
                null,
                token,
                id
            );
            if (batchDetails.Status == 1) {
                console.log("1");
                console.log(batchDetails?.Batches);
                setTab("Active");
                setActiveStudents(batchDetails?.Batches?.ActiveStudents);
                setData(batchDetails?.Batches);
                setload(false);
                setTimeout(() => {
                    setLoading(false);
                }, 200);
            }
        } catch (err) {
            // setLoading(false);
            console.log(err);
        }
    }
    const submithandler = async () => {
        let body = {
            Duration: inputref.current.value,
            BatchID: params.get("BatchId"),
        };
        try {
            setload(true);

            const Response = await Services.update_batchDuration(
                "POST",
                JSON.stringify(body),
                token
            );
            if (Response.Status === 1) {
                setEditClass(false);
                setload(false);
                SuccessSwal("Success", Response.Message);
                let details = data;

                details.Duration = Number(inputref.current.value);
                setData(details);
                getClassDetails(params.get("BatchId"));
            } else {
                setload(false);
                Error(Response?.Message);
            }
        } catch (err) {
            console.log(err);
            console.log(data);
            setload(false);
            Error("Something went wrong please try again");
        }
    };
    async function DeleteStudent(id, EnrollCourseID, StudentID) {
        console.log(StudentID);
        let body = {
            Status: 0,
            BatchName: data.BatchName,
            AdminUserType: loginData.Type,
            BatchID: data.BatchID,
            EnrollCourseID: EnrollCourseID,
            StudentID,
        };
        try {
            const batchDetails = await Services.deleteStudent(
                "PUT",
                JSON.stringify(body),
                token,
                id
            );
            if (batchDetails.Status == 1) {
                SuccessSwal("Success", batchDetails.Message);
                getBatchDetails(params.get("BatchId"));
            } else {
                warningSwal("Warning", batchDetails.Message);
            }
        } catch (err) {
            console.log(err);
        }
    }

    async function getClassDetails(batchId) {
        setBatchLoad(true);
        try {
            const batchDetails = await Services.classDetailsByBatchId(
                "GET",
                null,
                token,
                batchId
            );
            if (batchDetails.Status == 1) {
                setBatchLoad(false);
                setClassesCount(batchDetails.ClassesCount);
                setClasses(batchDetails.ScheduleClasess);
            }
        } catch (err) {
            setBatchLoad(false);
            console.log(err);
        }
    }

    useEffect(() => {
        color = Math.floor(Math.random() * 16777215).toString(16);
        getBatchDetails(params.get("BatchId"));
        getClassDetails(params.get("BatchId"));
    }, [params.get("scheduleID")]);
    useEffect(() => {
        if (editableId !== null && priceref.current) {
            priceref.current.focus();
        }
    }, [editableId]);
    const updateHandler = () => {
        localStorage.setItem("batchDetails", JSON.stringify(data));
        Navigate(`/Batches/update?BatchId=${params.get("BatchId")}`);
    };

    const deleteHandler = (e) => {
        e.stopPropagation();
        let studentName = e.currentTarget.getAttribute("studentName");
        let studentid = e.currentTarget.getAttribute("studentid");
        let id = e.currentTarget.getAttribute("id");
        let EnrollCourseID = e.currentTarget.getAttribute("EnrollCourseID");
        swal(`Are You sure you want to remove ${studentName}  ?`, {
            icon: "warning",
            dangerMode: true,
            buttons: {
                cancel: "Cancel",
                catch: {
                    text: "Delete",
                    value: "Logout",
                    className: detailsClasses["Delete"],
                },
                defeat: false,
            },
        }).then((value) => {
            switch (value) {
                case "Logout":
                    DeleteStudent(id, EnrollCourseID, studentid);
                    break;

                // default:
                //     swal("Got away safely!");
            }
        });
    };
    const editHandler = (e) => {
        e.stopPropagation();
        let id = e.currentTarget.getAttribute("id");
        let price = e.currentTarget.getAttribute("price");
        setEditableId(id);
        setPrice(price);
        priceref?.current?.focus();
    };
    const handleInputChange = (value) => {
        const validValue = value.match(/^\d*(\.\d{0,2})?$/);

        // If the input value is valid, update the form value
        if (validValue) {
            setPrice(value);
        } else {
            // Otherwise, revert to the last valid value
            setPrice(value.slice(0, -1));
        }
    };

    const updateBatch = () => {
        Services.updateBatchDetails(
            "PUT",
            JSON.stringify({ Status: 1 }),
            token,
            params.get("BatchId")
        )
            .then((response) => {
                if (response.Status === 1) {
                    getBatchDetails(params.get("BatchId"));
                    Swal({
                        title: "Batch updated ",
                        // text: "Batch Approve Request sent to Tutor Successfully",
                        icon: "success",
                        button: "OK",
                    });
                } else if (response.Status === 0) {
                    setLoading(false);
                    warningSwal("Warning", response.Message);
                }
            })
            .catch((err) => {
                // alert(err);
                setLoading(false);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    const blurHandler = async (event) => {
        let enrollId = event.currentTarget.getAttribute("courseid");

        if (Number(price) < 0) {
            warningSwal("warning", "Price cannot be  negative ");
            return;
        }
        try {
            const batchDetails = await Services.enrollCourseedit(
                "PUT",
                JSON.stringify({ PricePerClass: price }),
                token,
                enrollId
            );
            if (batchDetails.Status == 1) {
                // setEditableId("");
                getBatchDetails(params.get("BatchId"));
                setEditableId("");
            }
        } catch (err) {
            console.log(err);
        }
    };
    const handleKeyPress = async (event) => {
        let enrollId = event.currentTarget.getAttribute("courseid");
        if (event.key === "Enter") {
            if (Number(price) < 0) {
                warningSwal("warning", "Price cannot be Zero ");
                return;
            }
            try {
                const batchDetails = await Services.enrollCourseedit(
                    "PUT",
                    JSON.stringify({ PricePerClass: price }),
                    token,
                    enrollId
                );
                if (batchDetails.Status == 1) {
                    getBatchDetails(params.get("BatchId"));
                    setEditableId("");
                }
            } catch (err) {
                console.log(err);
            }
        }
    };

    const resendHandler = (e) => {
        Services.updateBatchDetails(
            "PUT",
            JSON.stringify({ Resend: 1, IsTutorAccepted: 0 }),
            token,
            params.get("BatchId")
        )
            .then((response) => {
                if (response.Status === 1) {
                    setload(true);
                    getBatchDetails(params.get("BatchId"));
                    SuccessSwal("Success", response.Message);
                } else if (response.Status === 0) {
                    warningSwal("Warning", response.Message);
                }
            })
            .catch((err) => {
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    const cancelClass_API = async () => {
        setLoading(true);
        try {
            const batchDetails = await Services.cancel_All_classes(
                "POST",
                JSON.stringify({ BatchID: params.get("BatchId") }),
                token
            );
            if (batchDetails.Status == 1) {
                getBatchDetails(params.get("BatchId"));
                getClassDetails(params.get("BatchId"));
                setEditableId("");
                SuccessSwal("Success", batchDetails.Message);
            } else {
                warningSwal("Warning", batchDetails.Message);
                // Error(batchDetails.Message);
            }
        } catch (err) {
            console.log(err);
        }
    };
    const cancelClass = async () => {
        swal(`Are You sure you want to Delete all future Class?`, {
            icon: "warning",
            dangerMode: true,
            buttons: {
                cancel: "No",
                catch: {
                    text: "Yes",
                    value: "Logout",
                    className: detailsClasses["Delete"],
                },
                defeat: false,
            },
        }).then((value) => {
            switch (value) {
                case "Logout":
                    cancelClass_API();
                    break;

                // default:
                //     swal("Got away safely!");
            }
        });
    };
    const completeClass = () => {
        setComplete(true);
    };
    const backButton = () => {
        Navigate(-1);
    };
    const NavHandler = (e) => {
        let type = e.currentTarget.getAttribute("btn-type");
        setTab(type);
        if (type === "Active") {
            setActiveStudents(data.ActiveStudents);
        } else {
            setActiveStudents(data.InActiveStudents);
        }
    };
    const addStudent = () => {
        if (loginData.Type === 5) {
            if (
                data.IsTutorAccepted != 1 &&
                data.Status != 3 &&
                data.Status != 4 &&
                data.Status != 5 &&
                data.Status != 6
            ) {
                return (
                    <button
                        onClick={() => setAddModal(true)}
                        disabled={
                            data.BatchSize === "One-to-One" &&
                            ActiveStudents.length > 0
                                ? true
                                : false
                        }>
                        Add students
                    </button>
                );
            }
        } else if (
            data.Status != 3 &&
            data.Status != 4 &&
            data.Status != 5 &&
            data.Status != 6
        ) {
            return (
                <button
                    onClick={() => setAddModal(true)}
                    disabled={
                        data.BatchSize === "One-to-One" &&
                        ActiveStudents.length > 0
                            ? true
                            : false
                    }>
                    Add students
                </button>
            );
        }
    };
    const logHandler = (e) => {
        let id = params.get("BatchId");
        let name = data.BatchName;
        Navigate("/ActivityLog", {
            state: {
                Active: "Batches",
                ActionByType: 7,
                ActionFor: id,
                name: name,
                Page: "Batches",
            },
        });
    };

    return (
        <>
            <CompleteModal
                setModal={setComplete}
                batchId={params.get("BatchId")}
                batchData={data}
                completemodal={complete}
                getBatchDetails={getBatchDetails}
                CompletedClasses={classCount[0]?.CompletedClassesCount}
                setload={setload}
            />
            <UpdateTutor
                setModal={setupdatetutor}
                showModal={updatetutor}
                data={data}
                getBatchDetails={getBatchDetails}
                id={params.get("BatchId")}
            />
            <Modal
                addModal={addModal}
                BatchSize={data?.BatchSize}
                CourseID={data?.CourseID}
                setAddModal={setAddModal}
                batchId={params.get("BatchId")}
                data={data}
            />
            <Helmet>
                <meta charSet="utf-8" />
                <title>Batch Details</title>
            </Helmet>
            {load && <Mainloader />}
            <Layout Active={"Batches"}>
                {loading ? (
                    <Mainloader />
                ) : (
                    <div className={detailsClasses["container"]}>
                        <div className={detailsClasses["control-btns"]}>
                            <button
                                onClick={backButton}
                                className={detailsClasses["back"]}>
                                Back
                            </button>
                            {data.IsTutorAccepted == 1 &&
                                data.Status != 3 &&
                                AccessControl(AccessKeys["Scheduled Classes"])
                                    ?.write === 1 && (
                                    <button
                                        className={detailsClasses["button"]}
                                        onClick={() => {
                                            sessionStorage.setItem(
                                                "batchDetails",
                                                JSON.stringify(data)
                                            );
                                            Navigate("/scheduleClass/add");
                                        }}>
                                        <BiPlus size={20} /> Schedule Class
                                    </button>
                                )}
                        </div>
                        <div className={detailsClasses["wrapper"]}>
                            <div className={detailsClasses["heading"]}>
                                <div className={detailsClasses["resend"]}>
                                    <h3>
                                        <span></span>Batch :{data.BatchName}
                                    </h3>
                                    {data.IsTutorAccepted == 2 && (
                                        <button onClick={resendHandler}>
                                            Resend
                                        </button>
                                    )}
                                    {data.Status == 3 && (
                                        <p
                                            className={
                                                detailsClasses["completed"]
                                            }>
                                            Completed
                                        </p>
                                    )}
                                    {data.Status == 4 && (
                                        <p
                                            className={
                                                detailsClasses["cancelled"]
                                            }>
                                            Gone cold
                                        </p>
                                    )}
                                    {data.Status == 5 && (
                                        <p
                                            className={
                                                detailsClasses["cancelled"]
                                            }>
                                            Batch Dissolved
                                        </p>
                                    )}
                                    {data.Status == 4 && (
                                        <p
                                            className={
                                                detailsClasses["cancelled"]
                                            }>
                                            Batch Dissolved
                                        </p>
                                    )}
                                    {data.Status == 6 && (
                                        <p
                                            className={
                                                detailsClasses["cancelled"]
                                            }>
                                            Parent Discontinued
                                        </p>
                                    )}
                                </div>
                                {data.Status == 4 && (
                                    <button
                                        onClick={updateBatch}
                                        className={detailsClasses["ongoing"]}>
                                        Move to Ongoing
                                    </button>
                                )}
                                {(data.Status == 1 || data.Status == 2) && (
                                    <div
                                        className={
                                            detailsClasses["batch-btns"]
                                        }>
                                        {data.IsTutorAccepted == 1 &&
                                            AccessControl(AccessKeys.Batches)
                                                ?.edit == 1 &&
                                            loginData.Type != 5 && (
                                                <button
                                                    className={
                                                        detailsClasses[
                                                            "action-btn"
                                                        ]
                                                    }
                                                    onClick={completeClass}>
                                                    Mark Finished
                                                </button>
                                            )}
                                        {AccessControl(AccessKeys.Batches)
                                            ?.edit == 1 &&
                                            data.Status != 3 &&
                                            data.Status != 4 &&
                                            data.Status != 5 &&
                                            data.Status != 6 && (
                                                <button
                                                    className={
                                                        detailsClasses[
                                                            "action-btn"
                                                        ]
                                                    }
                                                    onClick={updateHandler}>
                                                    <AiFillSetting size={18} />
                                                    Update
                                                </button>
                                            )}
                                    </div>
                                )}
                            </div>
                            <div className={detailsClasses["profile"]}>
                                <div className={detailsClasses["internal"]}>
                                    <h3 style={{ gap: "0.2rem" }}>
                                        Batch Details (
                                        <p
                                            style={{
                                                textDecoration: "underline",
                                                cursor: "pointer",
                                            }}
                                            onClick={logHandler}>
                                            Activity Logs
                                        </p>
                                        )
                                    </h3>
                                    <div>
                                        <h3>Tutor</h3>
                                        <span>:</span>
                                        <p style={{ maxWidth: "250px" }}>
                                            {data.TutorName}{" "}
                                            <button
                                                onClick={() =>
                                                    setupdatetutor(true)
                                                }>
                                                <MdEdit />
                                            </button>
                                        </p>
                                    </div>
                                    <div>
                                        <h3>Course</h3>
                                        <span>:</span>
                                        <p>{data.CourseName}</p>
                                    </div>

                                    {/* <div>
                                        <h3>Tutor Lesson Plan</h3>
                                        <span>:</span>
                                        <p>{data.PlanNumber_Batch}</p>
                                    </div> */}
                                    <div>
                                        <h3>Batch Start Date</h3>
                                        <span>:</span>
                                        <p>
                                            <Moment format="DD-MM-YYYY">
                                                {data.StartDate}
                                            </Moment>
                                        </p>
                                    </div>
                                    <div>
                                        <h3>Created By</h3>
                                        <span>:</span>
                                        <p>{data.AdminFullName}</p>
                                    </div>
                                    <div>
                                        <h3>Days</h3>
                                        <span>:</span>
                                        <p>
                                            {data.Days.map((item) => (
                                                <>
                                                    {item.Time} {item?.day},
                                                    <br />
                                                </>
                                            ))}
                                        </p>
                                    </div>
                                    <div>
                                        <h3>Duration</h3>
                                        <span>:</span>
                                        {editclass ? (
                                            <div
                                                className={
                                                    detailsClasses["edit-class"]
                                                }>
                                                {" "}
                                                <input
                                                    type="number"
                                                    defaultValue={
                                                        data?.Duration
                                                    }
                                                    ref={inputref}
                                                />
                                                <button onClick={submithandler}>
                                                    Submit
                                                </button>
                                            </div>
                                        ) : (
                                            <p>
                                                {data?.Duration} Min{" "}
                                                {data.Status != 3 &&
                                                    data.Status != 4 &&
                                                    data.Status != 5 &&
                                                    data.Status != 6 && (
                                                        <button
                                                            onClick={() =>
                                                                setEditClass(
                                                                    true
                                                                )
                                                            }>
                                                            <MdEdit />
                                                        </button>
                                                    )}
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className={detailsClasses["Table"]}>
                                <div
                                    className={
                                        detailsClasses["student-select"]
                                    }>
                                    <h3>Student Details</h3>
                                    {addStudent()}
                                </div>
                                <div className={detailsClasses["Navigator"]}>
                                    <button
                                        btn-type="Active"
                                        className={
                                            Tab === "Active"
                                                ? detailsClasses["active"]
                                                : ""
                                        }
                                        onClick={NavHandler}>
                                        Active
                                    </button>
                                    <button
                                        btn-type="Inactive"
                                        className={
                                            Tab === "Inactive"
                                                ? detailsClasses["active"]
                                                : ""
                                        }
                                        onClick={NavHandler}>
                                        Inactive
                                    </button>
                                </div>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Student Name</th>
                                            <th>Email</th>
                                            <th> Mobile</th>
                                            <th>Secondary Mobile</th>
                                            <th>Price</th>
                                            {data.Status != 3 &&
                                                data.Status != 4 &&
                                                data.Status != 5 &&
                                                data.Status != 6 &&
                                                Tab == "Active" && (
                                                    <th>Actions</th>
                                                )}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {ActiveStudents.length > 0 ? (
                                            ActiveStudents.map(
                                                (item, index) => {
                                                    return (
                                                        <tr
                                                            key={index}
                                                            // onClick={() =>
                                                            //     Navigate(
                                                            //         `/Students/details?id=${item.StudentID}`
                                                            //     )
                                                            // }
                                                        >
                                                            <td>
                                                                <NavLink
                                                                    to={`/Students/details?id=${item?.StudentID}`}>
                                                                    {
                                                                        item.StudentName
                                                                    }
                                                                </NavLink>
                                                            </td>
                                                            <td>
                                                                {
                                                                    item.StudentEmailID
                                                                }
                                                            </td>
                                                            <td>
                                                                {item.CountryCode &&
                                                                    item.CountryCode}{" "}
                                                                {
                                                                    item.ParentMobile
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    item.ParentSecondaryMobile
                                                                }
                                                            </td>
                                                            <td>
                                                                {editableId ==
                                                                item.BatchStudentID ? (
                                                                    <input
                                                                        className={
                                                                            detailsClasses[
                                                                                "price-input"
                                                                            ]
                                                                        }
                                                                        type="text"
                                                                        value={
                                                                            price
                                                                        }
                                                                        placeholder="price"
                                                                        onBlur={
                                                                            blurHandler
                                                                        }
                                                                        onFocus={() =>
                                                                            setPrice(
                                                                                item?.PricePerClass
                                                                            )
                                                                        }
                                                                        ref={
                                                                            priceref
                                                                        }
                                                                        onKeyDown={
                                                                            handleKeyPress
                                                                        }
                                                                        courseid={
                                                                            item.EnrollCourseID
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            handleInputChange(
                                                                                e
                                                                                    .target
                                                                                    .value
                                                                            )
                                                                        }
                                                                    />
                                                                ) : (
                                                                    <div
                                                                        div
                                                                        className={
                                                                            detailsClasses[
                                                                                "edit-price"
                                                                            ]
                                                                        }>
                                                                        <span>
                                                                            Rs{" "}
                                                                            {item?.PricePerClass
                                                                                ? item?.PricePerClass
                                                                                : 0}
                                                                        </span>
                                                                        {loginData.Type !=
                                                                            5 &&
                                                                        data.Status !=
                                                                            3 &&
                                                                        data.Status !=
                                                                            4 &&
                                                                        data.Status !=
                                                                            5 &&
                                                                        data.Status !=
                                                                            6 ? (
                                                                            <button
                                                                                studentName={
                                                                                    item.StudentName
                                                                                }
                                                                                className={
                                                                                    detailsClasses[
                                                                                        "delete"
                                                                                    ]
                                                                                }
                                                                                price={
                                                                                    item.PricePaid
                                                                                }
                                                                                onClick={
                                                                                    editHandler
                                                                                }
                                                                                id={
                                                                                    item.BatchStudentID
                                                                                }>
                                                                                <AiOutlineEdit color="black" />
                                                                            </button>
                                                                        ) : (
                                                                            data.IsTutorAccepted !=
                                                                                1 &&
                                                                            data.Status !=
                                                                                3 &&
                                                                            data.Status !=
                                                                                4 &&
                                                                            data.Status !=
                                                                                5 &&
                                                                            data.Status !=
                                                                                6 && (
                                                                                <button
                                                                                    studentName={
                                                                                        item.StudentName
                                                                                    }
                                                                                    className={
                                                                                        detailsClasses[
                                                                                            "delete"
                                                                                        ]
                                                                                    }
                                                                                    price={
                                                                                        item.PricePaid
                                                                                    }
                                                                                    onClick={
                                                                                        editHandler
                                                                                    }
                                                                                    id={
                                                                                        item.BatchStudentID
                                                                                    }>
                                                                                    <AiOutlineEdit color="black" />
                                                                                </button>
                                                                            )
                                                                        )}
                                                                    </div>
                                                                )}
                                                            </td>
                                                            {data.Status != 3 &&
                                                                data.Status !=
                                                                    4 &&
                                                                data.Status !=
                                                                    5 &&
                                                                data.Status !=
                                                                    6 &&
                                                                Tab ==
                                                                    "Active" && (
                                                                    <td>
                                                                        {loginData.Type !=
                                                                        5 ? (
                                                                            <button
                                                                                studentName={
                                                                                    item.StudentName
                                                                                }
                                                                                EnrollCourseID={
                                                                                    item.EnrollCourseID
                                                                                }
                                                                                className={
                                                                                    detailsClasses[
                                                                                        "delete"
                                                                                    ]
                                                                                }
                                                                                onClick={
                                                                                    deleteHandler
                                                                                }
                                                                                studentid={
                                                                                    item.StudentID
                                                                                }
                                                                                id={
                                                                                    item.BatchStudentID
                                                                                }>
                                                                                <MdDelete color="red" />
                                                                            </button>
                                                                        ) : (
                                                                            loginData.Type ==
                                                                                5 &&
                                                                            data.IsTutorAccepted !=
                                                                                1 && (
                                                                                <button
                                                                                    studentName={
                                                                                        item.StudentName
                                                                                    }
                                                                                    EnrollCourseID={
                                                                                        item.EnrollCourseID
                                                                                    }
                                                                                    className={
                                                                                        detailsClasses[
                                                                                            "delete"
                                                                                        ]
                                                                                    }
                                                                                    onClick={
                                                                                        deleteHandler
                                                                                    }
                                                                                    studentid={
                                                                                        item.StudentID
                                                                                    }
                                                                                    id={
                                                                                        item.BatchStudentID
                                                                                    }>
                                                                                    <MdDelete color="red" />
                                                                                </button>
                                                                            )
                                                                        )}
                                                                    </td>
                                                                )}
                                                        </tr>
                                                    );
                                                }
                                            )
                                        ) : (
                                            <tr>
                                                <td colSpan={6}>
                                                    No Data Found
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            <div
                                className={
                                    detailsClasses["scheduleclass-header"]
                                }>
                                <h3>Scheduled Classes</h3>
                                {data.Status != 3 &&
                                    data.IsTutorAccepted == 1 &&
                                    loginData.Type != 5 &&
                                    AccessControl(AccessKeys.Batches)?.edit ===
                                        1 && (
                                        <button onClick={cancelClass}>
                                            Delete
                                        </button>
                                    )}
                            </div>
                            {data.IsTutorAccepted == 1 &&
                                classCount.length > 0 && (
                                    <div
                                        className={
                                            detailsClasses["Class-count"]
                                        }>
                                        {classCount[0].PendingClassesCount !=
                                            null && (
                                            <p>
                                                Pending Classes:{" "}
                                                {
                                                    classCount[0]
                                                        .PendingClassesCount
                                                }
                                            </p>
                                        )}
                                        {classCount[0].CompletedClassesCount !=
                                            null && (
                                            <p>
                                                Completed Classes:{" "}
                                                {
                                                    classCount[0]
                                                        .CompletedClassesCount
                                                }
                                            </p>
                                        )}
                                        {classCount[0].CancelledClassesCount !=
                                            null && (
                                            <p>
                                                Cancelled Classes:{" "}
                                                {
                                                    classCount[0]
                                                        .CancelledClassesCount
                                                }
                                            </p>
                                        )}
                                    </div>
                                )}
                            <div className={detailsClasses["Table"]}>
                                {batchLoad ? (
                                    <TableLoad />
                                ) : (
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Class</th>
                                                <th>Start Date Time</th>
                                                <th>End Time</th>
                                                <th>Duration</th>
                                                <th>Lesson Plan</th>
                                                <th>Created At</th>
                                                <th>Cancelled On</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {classes.length > 0 ? (
                                                classes.map((item, index) => {
                                                    const time12 = moment(
                                                        item.Scheduled_EndTime,
                                                        "HH:mm:ss"
                                                    ).format("hh:mm A");

                                                    const timeStr =
                                                        item?.Scheduled_StartTime;

                                                    // Combine date and time
                                                    const dateTime = moment(
                                                        item?.Scheduled_StartDate
                                                    ).set({
                                                        hour: moment(
                                                            timeStr,
                                                            "HH:mm:ss"
                                                        ).hours(),
                                                        minute: moment(
                                                            timeStr,
                                                            "HH:mm:ss"
                                                        ).minutes(),
                                                        second: moment(
                                                            timeStr,
                                                            "HH:mm:ss"
                                                        ).seconds(),
                                                    });
                                                    return (
                                                        <tr
                                                            key={index}
                                                            id={
                                                                item.ScheduleClassID
                                                            }
                                                            onClick={(e) =>
                                                                Navigate(
                                                                    `/scheduledClasses/Details?scheduleID=${item.ScheduleClassID}`
                                                                )
                                                            }
                                                            name={
                                                                item.TutorName
                                                            }>
                                                            <td>
                                                                {
                                                                    item.ClassLessonName
                                                                }
                                                                {item.IsSpecialClass ===
                                                                    1 &&
                                                                    "(Special Class)"}
                                                            </td>
                                                            <td>
                                                                <Moment format="hh:mm A">
                                                                    {dateTime}
                                                                </Moment>
                                                                <br />
                                                                <Moment format="DD-MM-YYYY">
                                                                    {dateTime}
                                                                </Moment>
                                                            </td>
                                                            <td>{time12}</td>
                                                            <td>
                                                                {
                                                                    item.ClassDuration
                                                                }{" "}
                                                                Min
                                                            </td>

                                                            <td
                                                                style={{
                                                                    width: "7rem",
                                                                    wordBreak:
                                                                        "break-word",
                                                                }}>
                                                                {item.ClassStatus ==
                                                                    2 &&
                                                                    item?.LessonPlans_Class &&
                                                                    item.LessonPlans_Class.join(
                                                                        ","
                                                                    )}
                                                                {item
                                                                    ?.PartiallyCompleted
                                                                    ?.IsPartiallyCompleted ==
                                                                    1 && (
                                                                    <>
                                                                        {item
                                                                            ?.LessonPlans_Class
                                                                            ?.length >
                                                                            0 &&
                                                                            ","}
                                                                        {
                                                                            item
                                                                                ?.PartiallyCompleted
                                                                                .LessonPlan
                                                                        }
                                                                        (
                                                                        {
                                                                            item
                                                                                ?.PartiallyCompleted
                                                                                .Slide
                                                                        }
                                                                        )
                                                                    </>
                                                                )}
                                                            </td>

                                                            <td>
                                                                <Moment format="DD-MM-YYYY">
                                                                    {
                                                                        item.CreatedAt
                                                                    }
                                                                </Moment>{" "}
                                                            </td>
                                                            <td>
                                                                {item.ClassStatus ==
                                                                    3 &&
                                                                item.CancelledOn !=
                                                                    "0000-00-00 00:00:00" ? (
                                                                    <Moment
                                                                        format="hh:mm a Do MMM YYYY"
                                                                        utc>
                                                                        {
                                                                            new Date(
                                                                                item.CancelledOn
                                                                            )
                                                                        }
                                                                    </Moment>
                                                                ) : (
                                                                    "NA"
                                                                )}
                                                            </td>
                                                            <td>
                                                                {item.CancelRequestStatus ==
                                                                    1 ||
                                                                item.ClassStatus ==
                                                                    3 ? (
                                                                    <span
                                                                        className={
                                                                            detailsClasses[
                                                                                "cancelled"
                                                                            ]
                                                                        }>
                                                                        Cancelled
                                                                        By{" "}
                                                                        {item.CancelledByType ==
                                                                        1
                                                                            ? "Administrator"
                                                                            : item.CancelledByType ==
                                                                              2
                                                                            ? "RM"
                                                                            : item.CancelledByType ==
                                                                              3
                                                                            ? "Tutor"
                                                                            : item.CancelledByType ==
                                                                              4
                                                                            ? "Parent"
                                                                            : "SalesManager"}
                                                                    </span>
                                                                ) : item.ClassStatus ==
                                                                  1 ? (
                                                                    <span
                                                                        className={
                                                                            detailsClasses[
                                                                                "active-class"
                                                                            ]
                                                                        }>
                                                                        Pending
                                                                    </span>
                                                                ) : (
                                                                    <span
                                                                        className={
                                                                            detailsClasses[
                                                                                "completed"
                                                                            ]
                                                                        }>
                                                                        Completed
                                                                    </span>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            ) : (
                                                <tr>
                                                    <td colSpan={10}>
                                                        No Records Found
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </Layout>
        </>
    );
}

export default Details;
