import randomColor from "randomcolor";

export const Status = [{ label: 'Active', value: '1' }, { label: 'Completed', value: '2' }, { label: 'Cancelled', value: "3" }]


const timeConversion = (inputTimeString) => {

 // Parse the input time string
 const inputTime = new Date(`2000-01-01T${inputTimeString}`);

 // Convert to AM/PM format with local time zone
 const formattedTime = inputTime.toLocaleTimeString(undefined, {
  hour: "numeric",
  minute: "numeric",
  hour12: true,
 });
 return formattedTime
}

const DateTimeGenerator = (time, dateStr) => {
 const [hours, minutes] = time.split(":").map(Number);
 const formatted_date = new Date(dateStr);
 formatted_date.setUTCHours(hours);
 formatted_date.setUTCMinutes(minutes);
 const istOffset = 5.5 * 60 * 60 * 1000;
 return new Date(formatted_date.getTime() - istOffset);
};

export const CalenderDataGenerator = (data) => {
 // let calenderData = data.map((item, index) => {
 //  return {
 //   id: index,
 //   start: DateTimeGenerator(item.Scheduled_StartTime, item.Scheduled_StartDate),
 //   title: `${item.ClassStatus == 3 ? "✖" : ""} ${timeConversion(item.Scheduled_StartTime)} ${item.BatchName}`,
 //   end: DateTimeGenerator(item.Scheduled_EndTime, item.Scheduled_StartDate),
 //   color: (item?.ClassStatus == 3 || item?.CancelRequestStatus == 1) ? "red" : item.ClassStatus == 1 ? 'Burlywood' : 'rgba(4, 135, 54, 0.96)',
 //   TutorName: item.TutorName,
 //   BatchSize: item.BatchSize,
 //   BatchName: item.BatchName,
 //   CourseName: item.CourseName,
 //   ScheduleClassID: item.ScheduleClassID,
 //  };
 // });
 let newArray = []

 for (let i = 0; i < data.length; i++) {
  newArray.push({
   id: i,
   start: DateTimeGenerator(data[i].Scheduled_StartTime, data[i].Scheduled_StartDate),
   title: `${data[i].ClassStatus == 3 ? "✖" : ""} ${timeConversion(data[i].Scheduled_StartTime)} ${data[i].BatchName}`,
   end: DateTimeGenerator(data[i].Scheduled_EndTime, data[i].Scheduled_StartDate),
   color: (data[i]?.ClassStatus == 3 || data[i]?.CancelRequestStatus == 1) ? "red" : data[i].ClassStatus == 1 ? 'Burlywood' : 'rgba(4, 135, 54, 0.96)',
   TutorName: data[i].TutorName,
   BatchSize: data[i].BatchSize,
   BatchName: data[i].BatchName,
   CourseName: data[i].CourseName,
   ScheduleClassID: data[i].ScheduleClassID,
  })
 }
 return newArray;
};

//color: (data[i]?.ClassStatus == 3 || data[i]?.CancelRequestStatus == 1) ? "red" : data[i].ClassStatus == 1 ? 'Burlywood' : 'rgba(4, 135, 54, 0.96)',