import React from "react";
import Modal from "react-modal";
import Styles from "./modal.module.css";
import { useState, useEffect } from "react";
import Select from "react-select";
import { Services } from "../../../Services";
import Swal from "sweetalert";
import { warningSwal } from "../../../Util/Toast";
import ReactLoading from "react-loading";

function Popup({ showModal, setModal, data, getBatchDetails, id }) {
    const [loading, setLoading] = useState(false);

    const [tutorSearchString, setTutorSearchString] = useState("");
    const [selectedTutor, setSelectedTutor] = useState(null);
    const [tutors, setTutors] = useState([]);
    const [tutorSearchLoad, setTutorSearchLoad] = useState(false);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const closeModal = () => {
        setModal(false);
    };
    useEffect(() => {
        const debouncedSearch = setTimeout(async () => {
            if (
                typeof tutorSearchString == "string" &&
                tutorSearchString?.length > 0
            ) {
                setTutorSearchLoad(true);

                try {
                    const data = await Services.searchActiveTutor(
                        "POST",
                        JSON.stringify({ SearchString: tutorSearchString }),
                        token,
                        selectedTutor
                    );
                    const formattedOptions = data.Tutors?.filter(
                        (item) => item.TutorStatus == 1
                    )?.map((tutor) => ({
                        label: `${tutor.FirstName} ${tutor.LastName}`,
                        value: tutor.TutorID,
                        Email: tutor.EmailID,
                    }));
                    setTutors(formattedOptions);
                } catch (error) {
                    console.error("Error fetching options:", error);
                } finally {
                    setTutorSearchLoad(false);
                }
            }
        }, 300);

        return () => clearTimeout(debouncedSearch);
    }, [tutorSearchString]);
    const handleTutorChange = (searchString) => {
        setTutorSearchString(searchString);
    };
    const onTutorSelect = (searchString) => {
        setSelectedTutor(searchString);
    };
    const submit = async () => {
        if (selectedTutor) {
            let body = {
                BatchID: data?.BatchID,
                LensClassID: data?.LensClassID,
                TutorID: selectedTutor?.value,
            };
            try {
                const apicall = await Services.updateTutor(
                    "POST",
                    JSON.stringify(body),
                    token
                );
                if (apicall.Status == 1) {
                    getBatchDetails(id);
                    Swal({
                        title: "Tutor Updated ",
                        text: apicall.Message,
                        icon: "success",
                        button: "OK",
                    });
                    setModal(false);
                } else if (apicall.Status === 0) {
                    setLoading(false);
                    warningSwal("Warning", apicall.Message);
                }
            } catch (error) {
                console.error("Error fetching options:", error);
            }
        }
    };
    return (
        <>
            <Modal
                isOpen={showModal}
                contentLabel="Modal"
                className={Styles["modal"]}
                onRequestClose={closeModal}
                overlayClassName={Styles["overlay"]}>
                <div className={Styles["wrapper"]}>
                    <h3>Update Tutor</h3>
                    <div className={Styles["form-control"]}>
                        <label htmlFor="ContactNumber">
                            Tutor <span className="important">*</span>
                        </label>

                        <Select
                            options={tutors}
                            onInputChange={handleTutorChange}
                            value={selectedTutor}
                            onChange={onTutorSelect}
                            placeholder="Search Tutor"
                            menuPlacement="auto"
                            classNamePrefix="react-select_modal_update_tutor"
                            maxMenuHeight={"7rem"}
                            // noOptionsMessage={() => "No Student Found"}
                            isLoading={tutorSearchLoad}
                            styles={{
                                // Set the width to 100%
                                control: (provided) => ({
                                    ...provided,
                                    width: "100%",
                                }),
                            }}
                        />
                    </div>
                    {loading ? (
                        <ReactLoading
                            color="green"
                            type="spokes"
                            height={30}
                            width={30}
                        />
                    ) : (
                        <button onClick={submit}>submit</button>
                    )}
                </div>
            </Modal>
        </>
    );
}

export default Popup;
